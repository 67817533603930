import { ApiEmployee } from "../../../types/employees/employee.types.ts";
import { ModalProps } from "../../../ui/modal/modal.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Stack from "../../../ui/stack/Stack.tsx";
import Button from "../../../ui/button/Button.tsx";
import Modal from "../../../ui/modal/modal.tsx";
import { EmployeePersonalCard } from "../employee/components/EmployeePersonalCard.tsx";
import { EmployeeAvatar } from "../../../components/employee-avatar/EmployeeAvatar.tsx";

export interface EmployeeViewModalProps extends ModalProps {
  employee: ApiEmployee;
}

export function EmployeeViewModal({ employee, ...props }: EmployeeViewModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      title={t("Profile")}
      withCloser
      closeByEscEnabled
      size="md"
      variant={"side"}
      layout={"stretch"}
      actions={
        <Button
          className="w-full mt-4"
          size="lg"
          onClick={() => {
            close(id);
            navigate(`/employees/${employee.id}`);
          }}
        >
          {t("View profile")}
        </Button>
      }
    >
      <Stack>
        <Stack items={"center"}>
          <EmployeeAvatar size={"2xl"} employee={employee} />
        </Stack>
        {employee && <EmployeePersonalCard employee={employee} />}
      </Stack>
    </Modal>
  );
}
