import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { SelectOption } from "../../../../ui/select/SelectOption.tsx";
import { useTranslation } from "react-i18next";
import { ApiEmployeeStatus } from "../../../../types/employees/employee.types.ts";
import { useMemo } from "react";

export interface EmployeesDismissFilterSelectorProps {
  chosen: ApiEmployeeStatus;
  onChange: (type: ApiEmployeeStatus) => void;
}

export function EmployeesDismissFilterSelector({
  onChange,
  chosen,
}: EmployeesDismissFilterSelectorProps) {
  const { t } = useTranslation();
  const types: { type: ApiEmployeeStatus; title: string }[] = [
    {
      type: ApiEmployeeStatus.active,
      title: t("Active employees"),
    },
    {
      type: ApiEmployeeStatus.dismissed,
      title: t("Terminated employees"),
    },
  ];

  const chosenDismissTitle: string = useMemo(() => {
    return types.find((dismissItem) => dismissItem.type === chosen)?.title ?? t("Select");
  }, [chosen]);

  return (
    <DropdownMenu
      trigger={
        <Button
          variant={"secondary"}
          rightIcon={<FontAwesomeIcon icon={"fa-light fa-chevron-down shrink-0"} />}
        >
          {chosenDismissTitle}
        </Button>
      }
    >
      {types.map((dismissItem) => (
        <SelectOption
          key={`dismiss_type${dismissItem.type}`}
          item={{
            id: dismissItem.type,
            title: dismissItem.title,
            selected: dismissItem.type === chosen,
            item: {},
          }}
          isMulti={false}
          handleSelect={() => onChange(dismissItem.type)}
        />
      ))}
    </DropdownMenu>
  );
}
