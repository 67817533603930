import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { SidebarConfigItem } from "../../sidebar-service.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { SidebarItem } from "../SidebarItem.tsx";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import useEmployeeSearchInline from "../../../../queries/employees/use-employees.query.ts";
import { useDepartmentsSearchInline } from "../../../../queries/use-departments.query.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { PermissionType } from "../../../../types/role.types.ts";
import { usePermissionCheck } from "../../../../helpers/use-permission-check.ts";

export const SidebarTeamBaseMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { employee } = useEmployeeStore();
  const { renderEmployeeName } = useFormatter();
  const employees = useEmployeeSearchInline();
  const { data: departments } = useDepartmentsSearchInline();
  const { hasPermissionsToViewSettings } = usePermissionCheck();

  const menuConfig = useMemo(() => {
    const items: SidebarConfigItem[] = [
      {
        title: t("Employees"),
        to: "/employees",
        isActive: pathname === "/employees",
        count: employees.data?.metadata.total_items,
      },
    ];

    if (employee && employee.permissions.see_organization_structure == PermissionType.edit) {
      items.push({
        title: t("Organization chart"),
        to: "/employees/chart",
        isActive: pathname === "/employees/chart",
      });
    }

    if (employee && employee.permissions.see_departments_structure == PermissionType.edit) {
      items.push({
        title: t("Departments"),
        to: "/departments/chart",
        isActive: pathname === "/departments/chart",
        count: departments?.metadata.total_items,
      });
    }

    return items;
  }, [pathname, employees.data, departments]);

  const settingsItem = useMemo(() => {
    return {
      title: t("Settings"),
      to: "/settings",
      isActive: pathname.startsWith("/settings"),
    } as SidebarConfigItem;
  }, [pathname, t]);

  const profileItem = useMemo(() => {
    const employeeProfilePath = `/employees/${employee?.id}`;
    return {
      title: t("My profile"),
      to: employeeProfilePath,
      isActive: pathname === employeeProfilePath,
    } as SidebarConfigItem;
  }, [pathname, employee?.id]);

  return (
    <Stack className={"px-4"} gap={"lg"}>
      <SidebarItem
        to={profileItem.to}
        isActive={profileItem.isActive}
        title={profileItem.title}
        icon={
          <Avatar
            variant={"plain"}
            size="xs"
            url={employee?.avatar_url}
            acronym={employee ? renderEmployeeName(employee) : ""}
          />
        }
      />
      <Stack gap={"xs"}>
        {menuConfig.map((item, index) => (
          <SidebarItem
            to={item.to}
            key={"team-sidebar-" + index}
            isActive={item.isActive}
            title={item.title}
            count={item.count}
          />
        ))}
      </Stack>
      {hasPermissionsToViewSettings && (
        <SidebarItem
          to={settingsItem.to}
          isActive={settingsItem.isActive}
          title={settingsItem.title}
          icon={<FontAwesomeIcon icon="fa-light fa-gear" className="self-center" />}
        />
      )}
    </Stack>
  );
};
