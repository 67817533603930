import { ApiEmployeeShort } from "../employees/employee.types.ts";
import { ApiJobShort } from "../recruit/job.types.ts";
import { ApiPipelineStage } from "../recruit/stages.types.ts";
import { SelectItem } from "../../ui/select/select.types.ts";
import i18n from "../../i18n.ts";
import { ApiSource } from "../recruit/source.types.ts";
import { ApiCandidateDisqualificationReason } from "../recruit/disqualification-reason.types.ts";

export interface ApiAnalyticsRecruitingStagesFlowReportItemStage {
  pipeline_stage: ApiPipelineStage;
  applicants_ids: number[];
  count_in: number;
  count_out: number;
}

export interface ApiAnalyticsRecruitingStagesFlowReportItem {
  date_report_from: Date;
  date_report_to: Date;
  employee: ApiEmployeeShort;
  job: ApiJobShort;
  stages: ApiAnalyticsRecruitingStagesFlowReportItemStage[];
  week_number: number;
}

export interface ApiAnalyticsRecruitingStagesFlowReportConfig {
  stages: ApiPipelineStage[];
}

export interface ApiAnalyticsRecruitingStagesFlowReport {
  config: ApiAnalyticsRecruitingStagesFlowReportConfig;
  report: ApiAnalyticsRecruitingStagesFlowReportItem[];
}

export enum ApiAnalyticsAggregationDate {
  day = "day",
  week = "week",
  month = "month",
  quarter = "quarter",
  year = "year",
}

export interface ApiAnalyticsRecruitingStageFlowFilter {
  employee_id: number[];
  job_id: number[];
  date_start?: Date | null;
  date_end?: Date | null;
  aggregation_date_mode: ApiAnalyticsAggregationDate;
  group_owner?: boolean;
  group_jobs?: boolean;
}

// V2
export interface ApiAnalyticsRecruitingFilter {
  date_start?: Date;
  date_end?: Date;
  recruiter_ids?: number[];
  job_ids?: number[];
  aggregation_date_mode?: ApiAnalyticsAggregationDate;

  // stage
  group_by?: ApiAnalyticsGroupBy | null;

  format?: string;
}

export enum ApiAnalyticsGroupBy {
  owner = "owner",
  job = "job",
  none = "none",
}

export interface ApiAnalyticsRecruitingReportRowBase {
  value: number;
  value2: number;
}

/* Stage Flow */

export interface ApiAnalyticsRecruitingStageFlowReportRowItem
  extends ApiAnalyticsRecruitingReportRowBase {
  pipeline_stage: ApiPipelineStage;
  applicants_ids: number[];
}

export interface ApiAnalyticsRecruitingStageFlowReportRow
  extends ApiAnalyticsRecruitingReportRowBase {
  date_report_from: Date;
  date_report_to: Date;
  employee?: ApiEmployeeShort;
  job?: ApiJobShort;
  stages: ApiAnalyticsRecruitingStageFlowReportRowItem[];
}

export interface ApiAnalyticsRecruitingStageFlowReport {
  filter: ApiAnalyticsRecruitingFilter;
  config: {
    stages: ApiPipelineStage[];
  };
  report: {
    rows: ApiAnalyticsRecruitingStageFlowReportRow[];
  };
}

/* Source Efficiency */

export interface ApiAnalyticsRecruitingSourceEfficiencyReportRowItem
  extends ApiAnalyticsRecruitingReportRowBase {
  applicants_ids: number[];
  source: ApiSource;
}

export interface ApiAnalyticsRecruitingSourceEfficiencyReportRow {
  sources: ApiAnalyticsRecruitingSourceEfficiencyReportRowItem[];
  pipeline_stage: ApiPipelineStage;
}

export interface ApiAnalyticsRecruitingSourceEfficiencyReport {
  filter: ApiAnalyticsRecruitingFilter;
  config: {
    stages: ApiPipelineStage[];
    sources: ApiSource[];
  };
  report: {
    rows: ApiAnalyticsRecruitingSourceEfficiencyReportRow[];
  };
}

/* Disqualification Reasons */

export interface ApiAnalyticsDisqualificationReasonsReportRowItem
  extends ApiAnalyticsRecruitingReportRowBase {
  applicants_ids: number[];
  reason: ApiCandidateDisqualificationReason;
}

export interface ApiAnalyticsRecruitingDisqualificationReasonsReportRow {
  reasons: ApiAnalyticsDisqualificationReasonsReportRowItem[];
  pipeline_stage: ApiPipelineStage;
}

export interface ApiAnalyticsRecruitingDisqualificationReasonsReport {
  filter: ApiAnalyticsRecruitingFilter;
  config: {
    stages: ApiPipelineStage[];
    reasons: ApiCandidateDisqualificationReason[];
  };
  report: {
    rows: ApiAnalyticsRecruitingDisqualificationReasonsReportRow[];
  };
}

/* Closing Time */
export interface ApiAnalyticsRecruitingClosingTimeReportRow
  extends ApiAnalyticsRecruitingReportRowBase {
  pipeline_stage: ApiPipelineStage;
  applicants_ids: number[];
}

export interface ApiAnalyticsRecruitingClosingTimeReport {
  filter: ApiAnalyticsRecruitingFilter;
  config: {
    stages: ApiPipelineStage[];
  };
  report: {
    hired_candidate_count: number;
    hired_from_jobs_count: number;
    average_days_for_closing: number;
    rows: ApiAnalyticsRecruitingClosingTimeReportRow[];
  };
}

export const getAnalyticsAggregationDateOptions = () => {
  return [
    {
      id: ApiAnalyticsAggregationDate.day,
      title: i18n.t("group_period_by_day"),
    },
    {
      id: ApiAnalyticsAggregationDate.week,
      title: i18n.t("group_period_by_week"),
    },
    {
      id: ApiAnalyticsAggregationDate.month,
      title: i18n.t("group_period_by_month"),
    },
    {
      id: ApiAnalyticsAggregationDate.quarter,
      title: i18n.t("group_period_by_quarter"),
    },
    {
      id: ApiAnalyticsAggregationDate.year,
      title: i18n.t("group_period_by_year"),
    },
  ] as SelectItem[];
};
