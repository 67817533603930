import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Input } from "../../../../ui/input/Input.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import {
  ApiJobSavePayload,
  getBackgroundByEmoji,
  getRandomDefaultEmoji,
  JobEdit,
  JobStatus,
  JobStatusItems,
} from "../../../../types/recruit/job.types.ts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { SelectItem } from "../../../../ui/select/select.types.ts";
import Select from "../../../../ui/select/Select.tsx";
import Multiselect from "../../../../ui/select/Multiselect.tsx";
import { RichEditor } from "../../../../ui/rich-editor/RichEditor.tsx";
import { useCurrencies } from "../../../../queries/currencies.query.ts";
import { useDepartments } from "../../../../queries/use-departments.query.ts";
import { usePositions } from "../../../../queries/use-positions.query.ts";
import { useDivisionsSearch } from "../../../../queries/use-divisitions.query.ts";
import { ApiDivisionsFilterDefault } from "../../../../types/division.types.ts";
import { usePipelines } from "../../../../queries/recruit/use-pipelines.query.ts";
import { useJobSave } from "../../../../queries/recruit/use-jobs.query.ts";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import {
  ApiEmployeeFilter,
  EmployeeFilterDefault,
} from "../../../../types/employees/employee.types.ts";
import { useEmployees } from "../../../../queries/employees/use-employees.query.ts";
import { Title } from "../../../../components/title/Title.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import Box from "../../../../ui/box/Box.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { EmojiPickerDropdown } from "../../../../components/emoji/EmojiPickerDropdown.tsx";
import { Tag } from "../../../../components/tag/Tag.tsx";

export interface JobEditModalProps extends ModalProps {
  job: JobEdit;
}

export const JobEditModal = ({ job, ...props }: JobEditModalProps) => {
  const { t } = useTranslation();
  const { id } = { ...props };
  const { renderEmployeeName } = useFormatter();
  const { close } = useModals();

  const JobSchemaObject = yup.object({
    title: yup.string().required(i18n.t("Field is required")),
    status: yup.mixed<JobStatus>().oneOf(Object.values(JobStatus)).required(),
    division_id: yup.number(),
    department_id: yup.number().required(i18n.t("Field is required")),
    position_id: yup.number(),
    level_id: yup.number(),
    salary_min: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .default(undefined),
    salary_max: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .default(undefined),
    currency_code: yup.string(),
    recruiter_ids: yup.array().of(yup.number().required()).required(),
    stakeholder_ids: yup.array().of(yup.number().required()).required(),
    pipeline_id: yup.number().required(i18n.t("Field is required")),
    description: yup.string().default(""),
    emoji: yup.string().required(i18n.t("Field is required")),
  });

  interface JobSchema extends yup.InferType<typeof JobSchemaObject> {}

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<JobSchema>({
    mode: "onSubmit",
    resolver: yupResolver(JobSchemaObject),
    defaultValues: {
      title: job.title,
      status: job.status,
      description: job.description,
      division_id: job.division?.id,
      department_id: job.department?.id,
      salary_min: job.salary_min || undefined,
      salary_max: job.salary_max || undefined,
      currency_code: job.currency?.code,
      position_id: job.position?.id,
      level_id: job.level?.id,
      recruiter_ids: job.recruiters.map((x) => x.id),
      stakeholder_ids: job.stakeholders.map((x) => x.id),
      pipeline_id: job.pipeline?.id,
      emoji: job.emoji && job.emoji.length > 0 ? job.emoji : getRandomDefaultEmoji(),
    },
  });

  const watchEmoji = watch("emoji");
  const { data: currencies, isLoading: isCurrenciesLoading } = useCurrencies();
  const { data: departments, isLoading: isDepartmentsLoading } = useDepartments();
  const { data: positions, isLoading: isPositionsLoading } = usePositions();
  const [levels, setLevels] = useState<SelectItem[]>([]);

  const [divisionsFilter, setDivisionsFilter] = useState(ApiDivisionsFilterDefault);
  const { data: divisions, isLoading: isDivisionsLoading } = useDivisionsSearch(divisionsFilter);
  const { data: pipelines, isLoading: isPipelinesLoading } = usePipelines();

  const currenciesMapped = useMemo(() => {
    if (currencies) {
      return currencies.map((x) => {
        return { id: x.code, title: x.code };
      });
    }
    return [];
  }, [currencies]);

  const selectedPipelineId = useWatch({ control, name: "pipeline_id" });

  const selectedPipeline = useMemo(() => {
    if (pipelines) {
      return pipelines.find((x) => x.id == selectedPipelineId);
    }
    return undefined;
  }, [pipelines, selectedPipelineId]);

  // set pipeline by default
  useEffect(() => {
    if (pipelines && job.pipeline == undefined && pipelines.length > 0) {
      const defaultPipeline = pipelines.find((x) => x.is_default) || pipelines[0];
      setValue("pipeline_id", defaultPipeline.id);
    }
  }, [pipelines]);

  const jobSave = useJobSave(control);

  const onSubmit = handleSubmit((jobData) => {
    const payload: ApiJobSavePayload = {
      title: jobData.title,
      status: jobData.status,
      description: jobData.description,
      division_id: jobData.division_id,
      department_id: jobData.department_id,
      salary_min: jobData.salary_min,
      salary_max: jobData.salary_max,
      currency_code: jobData.currency_code,
      position_id: jobData.position_id,
      level_id: jobData.level_id,
      recruiter_ids: jobData.recruiter_ids,
      stakeholder_ids: jobData.stakeholder_ids,
      pipeline_id: jobData.pipeline_id,
      emoji: jobData.emoji,
    };

    jobSave.mutate(
      { id: job.id, payload },
      {
        onSuccess: (jobSaved) => {
          if (job.id > 0) {
            analyticsService.trackEvent(analyticEvents.jobs.edited, {
              [analyticProperties.id]: jobSaved.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.jobs.created, {
              [analyticProperties.id]: jobSaved.id,
            });
          }
          close(id);
        },
      }
    );
  });

  const recruiterOptions = useMemo(() => {
    if (job?.recruiters) {
      return job.recruiters.map((x) => {
        return { id: x.id, title: renderEmployeeName(x) };
      }) as SelectItem[];
    }
    return [];
  }, [job]);

  const stakeholderOptions = useMemo(() => {
    if (job?.stakeholders) {
      return job.stakeholders.map((x) => {
        return { id: x.id, title: renderEmployeeName(x) };
      }) as SelectItem[];
    }
    return [];
  }, [job]);

  const [employeeRecruitersFilter, setEmployeeRecruitersFilter] =
    useState<ApiEmployeeFilter>(EmployeeFilterDefault);
  const { data: employeesRecruitersSearch, isLoading: isEmployeesRecruitingIsLoading } =
    useEmployees(employeeRecruitersFilter);
  const employeesRecruitersSearchOptions = useMemo(() => {
    if (employeesRecruitersSearch?.items) {
      return employeesRecruitersSearch.items.map((x) => {
        return { id: x.id, title: renderEmployeeName(x) };
      }) as SelectItem[];
    }
    return [] as SelectItem[];
  }, [employeesRecruitersSearch]);

  const [employeeStakeholdersFilter, setEmployeeStakeholdersFilter] =
    useState<ApiEmployeeFilter>(EmployeeFilterDefault);
  const { data: employeesStakeholdersSearch, isLoading: isEmployeesStakeholdersIsLoading } =
    useEmployees(employeeStakeholdersFilter);
  const employeesStakeholdersSearchOptions = useMemo(() => {
    if (employeesStakeholdersSearch?.items) {
      return employeesStakeholdersSearch.items.map((x) => {
        return { id: x.id, title: renderEmployeeName(x) };
      }) as SelectItem[];
    }
    return [] as SelectItem[];
  }, [employeesStakeholdersSearch]);

  return (
    <Modal
      {...props}
      withCloser
      layout={"page"}
      size={"lg"}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} isLoading={jobSave.isPending} onClick={onSubmit} size={"lg"}>
            {t("Save")}
          </Button>
        </Stack>
      }
      background={
        <img
          src={getBackgroundByEmoji(watchEmoji)}
          alt={"background"}
          className={"w-full h-[500px] object-cover"}
        />
      }
    >
      <form onSubmit={onSubmit} className={"gap-sm flex flex-col mt-56"}>
        <Stack className={"p-8 bg-light/80 rounded-md shadow-sm backdrop-blur-md"} gap={"2xl"}>
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <EmojiPickerDropdown
                onEmojiSelect={(emoji) => {
                  onChange(emoji);
                }}
              >
                <Box className={"relative w-40 h-40 group cursor-pointer "}>
                  <Avatar variant={"opaque"} url={undefined} emoji={value} size={"2xl"} />
                  <Box
                    className={
                      "bg-dark/20 opacity-0 group-hover:opacity-100 absolute inset-0 rounded-lg flex items-center justify-center"
                    }
                  >
                    <FontAwesomeIcon
                      icon={"fa-light fa-icons"}
                      className={"text-[74px] text-light "}
                    />
                  </Box>
                </Box>
              </EmojiPickerDropdown>
            )}
            name={"emoji"}
          />

          <Stack gap={"lg"}>
            <Title
              header={t("Job")}
              caption={t(
                "Enter details for your next job opening, including all available information"
              )}
              size={"lg"}
            />
            <Input
              label={t("Title")}
              required={true}
              type={"text"}
              {...register("title")}
              error={errors.title?.message}
            />
          </Stack>
        </Stack>

        <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
          <Title header={t("Job summary")} size={"md"} />
          <Stack>
            <Controller
              control={control}
              name={`status`}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Status")}
                  options={JobStatusItems()}
                  // isLoading={isDataLoading}
                  emptyTitle={t("Select")}
                  value={value}
                  onChange={onChange}
                  error={errors.status?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`division_id`}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Division")}
                  options={divisions?.items}
                  isLoading={isDivisionsLoading}
                  searchable
                  onSearchOptions={(value) => {
                    setDivisionsFilter({ ...divisionsFilter, text: value });
                  }}
                  emptyTitle={t("Select")}
                  searchOptions={divisions?.items || []}
                  value={value}
                  onChange={onChange}
                  error={errors.division_id?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`department_id`}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Department")}
                  required={true}
                  searchable
                  options={departments}
                  isLoading={isDepartmentsLoading}
                  emptyTitle={t("Select")}
                  value={value}
                  onChange={onChange}
                  error={errors.department_id?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`position_id`}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Position")}
                  options={positions}
                  emptyTitle={t("Select")}
                  isLoading={isPositionsLoading}
                  searchable
                  value={value}
                  onChange={(value) => {
                    const position = positions?.find((x) => x.id == value);
                    if (position) {
                      setLevels(position.levels);
                    }
                    setValue("level_id", undefined);
                    onChange(value);
                  }}
                  error={errors.position_id?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`level_id`}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Level")}
                  options={levels}
                  isLoading={isPositionsLoading}
                  emptyTitle={t("Select")}
                  value={value}
                  onChange={onChange}
                  error={errors.level_id?.message}
                />
              )}
            />
          </Stack>
        </Stack>

        <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
          <Title header={t("Compensation")} size={"md"} />
          <Stack>
            <Controller
              control={control}
              name={`currency_code`}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Currency")}
                  options={currenciesMapped}
                  isLoading={isCurrenciesLoading}
                  emptyTitle={t("Select")}
                  value={value}
                  searchable
                  onChange={onChange}
                  error={errors.currency_code?.message}
                />
              )}
            />
            <Input
              label={t("Min Salary")}
              type={"text"}
              {...register("salary_min")}
              error={errors.salary_min?.message}
            />

            <Input
              label={t("Max Salary")}
              type={"text"}
              {...register("salary_max")}
              error={errors.salary_max?.message}
            />
          </Stack>
        </Stack>

        <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
          <Title
            header={t("Team")}
            size={"md"}
            caption={t("Assign teammates to collaborate on this job opening")}
          />
          <Stack>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Multiselect
                  label={t("Recruiters")}
                  emptyTitle={t("Select")}
                  values={value}
                  options={recruiterOptions}
                  searchable
                  loadOptionsOnOpen
                  searchOptions={employeesRecruitersSearchOptions}
                  isLoading={isEmployeesRecruitingIsLoading}
                  onSearchOptions={(value) => {
                    setEmployeeRecruitersFilter({
                      ...employeeRecruitersFilter,
                      text: value,
                    });
                  }}
                  onChange={onChange}
                  error={errors.recruiter_ids?.message}
                />
              )}
              control={control}
              name={"recruiter_ids"}
            />

            <Controller
              render={({ field: { value, onChange } }) => (
                <Multiselect
                  label={t("Stakeholders")}
                  emptyTitle={t("Select")}
                  values={value}
                  options={stakeholderOptions}
                  searchable
                  searchOptions={employeesStakeholdersSearchOptions}
                  isLoading={isEmployeesStakeholdersIsLoading}
                  onSearchOptions={(value) => {
                    setEmployeeStakeholdersFilter({
                      ...employeeStakeholdersFilter,
                      text: value,
                    });
                  }}
                  loadOptionsOnOpen
                  onChange={onChange}
                  error={errors.stakeholder_ids?.message}
                />
              )}
              control={control}
              name={"stakeholder_ids"}
            />
          </Stack>
        </Stack>

        <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
          <Title
            header={t("Pipeline")}
            caption={t("Set up the stages for processing this job")}
            size={"md"}
          />
          <Stack>
            <Stack gap={"sm"}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Select
                    required={true}
                    label={t("Pipeline")}
                    options={pipelines}
                    emptyTitle={t("Select")}
                    isLoading={isPipelinesLoading}
                    value={value}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    error={errors.pipeline_id?.message}
                  />
                )}
                control={control}
                name={"pipeline_id"}
              />
              {selectedPipeline && selectedPipeline.stages.length > 0 && (
                <Stack direction={"horizontal"} gap={"sm"} className={"flex-wrap"}>
                  {selectedPipeline.stages.map((stage) => (
                    <Tag key={`job-stage-${stage.id}`} color={"#000"}>
                      {stage.title}
                    </Tag>
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack className={"p-8 bg-light rounded-md"}>
          <Title
            header={t("Description")}
            caption={t("Describe your job in detail using the editor")}
            size={"md"}
            paddingBottom
          />
          <Stack>
            <Controller
              render={({ field: { value, onChange } }) => (
                <RichEditor
                  value={value || ""}
                  onChange={onChange}
                  error={errors.description?.message}
                  placeholder={t("Describe your job in detail using the editor")}
                />
              )}
              control={control}
              name={"description"}
            />
          </Stack>
        </Stack>
      </form>
      <ServerErrorField errors={errors} />
    </Modal>
  );
};
