import { ApiFilterBase } from "../filter.types.ts";
import { ApiPermissions, ApiRoleShort, EmployeePermissions } from "../role.types.ts";
import { SelectItem } from "../../ui/select/select.types.ts";
import { ApiEmployeePosition } from "./employee-position.types.ts";
import { ApiOrganizationSettings, Languages } from "../organization.types.ts";
import { ApiPositionShort } from "../position.types.ts";
import { ApiCountry } from "../location.types.ts";

export interface ApiAuthorization {
  access_token: string;
}

export interface ApiUserSignupPayload {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  organization_title: string;
  job_title: string;
  language: string;
  timezone: string;
}

export enum EmployeeFullNameRenderType {
  first_last = "first_last",
  last_first = "last_first",
  f_last = "f_last",
}

export enum EmployeeGender {
  male = "male",
  female = "female",
}

export interface ApiEmployeeShort {
  id: number;
  first_name: string;
  last_name: string;
  middle_name?: string;
  avatar_url?: string;
  background_url?: string;
}

export interface ApiEmployeeWithRoles {
  employee: ApiEmployee;
  roles: ApiRoleShort[];
}

export interface ApiEmployeeBase extends ApiEmployeeShort {
  email: string;
  email_personal?: string;
  date_hired_on: Date;
  date_dismissal?: Date;
  phone?: string;
  phone_personal?: string;
  date_birth?: Date;
  gender?: EmployeeGender;
  color_palette?: string[];
  personnel_number?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: ApiCountry;
  salary?: number;
  salary_description?: string;
}

export interface ApiEmployee extends ApiEmployeeBase {
  employee_position: ApiEmployeePosition;
  permissions: EmployeePermissions;
}

export interface ApiManagerEmployee extends ApiEmployeeBase {
  position?: ApiPositionShort;
}

export interface ApiMeEmployee extends ApiEmployeeBase {
  language?: Languages;
  timezone?: string;
  organization_id: number;
  organization: ApiOrganizationSettings;
  email_confirmed: boolean;
  available_languages: string[];

  permissions: ApiPermissions;
}

export interface ApiEmployeeFilter extends ApiFilterBase {
  text?: string;
  manager_ids?: number[];
  division_ids?: number[];
  department_ids?: number[];
  position_ids?: number[];
  employee_status?: ApiEmployeeStatus;
}

export enum ApiEmployeeStatus {
  active = "active",
  dismissed = "dismissed",
  all = "all",
}

export interface ApiEmployeeEditPayload {
  last_name?: string | null;
  first_name?: string | null;
  middle_name?: string | null;
  email_personal?: string | null;
  email?: string | null;
  date_hired_on?: Date | null;
  phone_personal?: string | null;
  phone?: string | null;
  date_birth?: Date | null;
  gender?: EmployeeGender | string | null;
  personnel_number?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country_id?: number | null;
  salary?: number | null;
  salary_description?: string | null;
}

export interface EmployeeGenderItem extends SelectItem {}

export const EmployeeGenders: EmployeeGenderItem[] = [
  {
    id: EmployeeGender.male,
    title: "gender_male",
  },
  {
    id: EmployeeGender.female,
    title: "gender_female",
  },
];

export interface ApiEmployeeAccountSettingsSavePayload {
  language?: Languages;
  timezone?: string;
}

export const EmployeeFilterDefault: ApiEmployeeFilter = {
  page_number: 1,
  text: "",
  employee_status: ApiEmployeeStatus.active,
};

export interface ApiEmployeeDismissPayload {
  dismissal_reason_id: number;
  date_from: Date;
  comment: string;
}
