import { api } from "../axios.ts";
import {
  ApiAuthorization,
  ApiEmployee,
  ApiEmployeeAccountSettingsSavePayload,
  ApiEmployeeEditPayload,
  ApiEmployeeFilter,
  ApiEmployeeWithRoles,
  ApiMeEmployee,
  ApiUserSignupPayload,
} from "../../types/employees/employee.types.ts";
import { ApiPagedData } from "../../types/filter.types.ts";
import {
  ApiEmployeeAssignPositionPayload,
  ApiEmployeePosition,
} from "../../types/employees/employee-position.types.ts";
import { ApiEmployeeChartItem } from "../../pages/employees/chart/employees-chart.types.ts";
import { ApiPhotoUpdate } from "../../types/comment.types.ts";

export const apiEmployees = {
  create: async function (employee: ApiEmployeeEditPayload) {
    return api.post<ApiEmployee>("/api/v1/employees", employee);
  },
  search: async function (filter: ApiEmployeeFilter, signal: AbortSignal) {
    return api.post<ApiPagedData<ApiEmployee>>("/api/v1/employees/search", filter, signal);
  },
  chart: async function (manager_id: number | undefined) {
    return api.post<ApiEmployeeChartItem[]>("/api/v1/employees/chart", {
      manager_id,
    });
  },
  searchWithRoles: async function (filter: ApiEmployeeFilter, signal: AbortSignal) {
    return api.post<ApiPagedData<ApiEmployeeWithRoles>>(
      `/api/v1/roles/employees-roles`,
      filter,
      signal
    );
  },
  getById: async function (id: number | string) {
    return api.get<ApiEmployee>(`/api/v1/employees/${id}`);
  },
  getByListOfId: async function (ids: number[]) {
    return api.post<ApiEmployee[]>(`/api/v1/employees/get-by-ids`, {
      employee_ids: ids,
    });
  },
  update: async function (id: number, payload: ApiEmployeeEditPayload) {
    return api.put<ApiEmployee>(`/api/v1/employees/${id}`, payload);
  },
  setAvatar: async function (employeeId: number, fileId: number) {
    return api.put<ApiPhotoUpdate>(`/api/v1/employees/${employeeId}/avatar`, {
      file_id: fileId,
    });
  },
  deleteAvatar: async function (employeeId: number) {
    return api.delete<ApiPhotoUpdate>(`/api/v1/employees/${employeeId}/avatar`);
  },
  setBackground: async function (employeeId: number, fileId: number) {
    return api.put<ApiPhotoUpdate>(`/api/v1/employees/${employeeId}/background`, {
      file_id: fileId,
    });
  },
  deleteBackground: async function (employeeId: number) {
    return api.delete<ApiPhotoUpdate>(`/api/v1/employees/${employeeId}/background`);
  },
  getMe: async function () {
    return api.get<ApiMeEmployee>("/api/v1/employees/me");
  },
  updateAccountSettings: async function (payload: ApiEmployeeAccountSettingsSavePayload) {
    return api.put<ApiMeEmployee>("/api/v1/employees/me", payload);
  },

  resetConfirmEmail: async function () {
    return api.post("/api/v1/employees/resend-confirm-email", {});
  },
  // Employee Positions
  positionCreate: async function (id: number, payload: ApiEmployeeAssignPositionPayload) {
    return api.post<ApiEmployee>(`/api/v1/employees/${id}/positions`, payload);
  },
  positionUpdate: async function (
    id: number,
    positionId: number,
    payload: ApiEmployeeAssignPositionPayload
  ) {
    return api.put<ApiEmployee>(`/api/v1/employees/${id}/positions/${positionId}`, payload);
  },
  positionDelete: async function (employeeId: number, employeePositionId: number) {
    return api.delete(`/api/v1/employees/${employeeId}/positions/${employeePositionId}`);
  },
  positionSearch: async function (employeeId: number, pageNumber: number) {
    return api.post<ApiPagedData<ApiEmployeePosition>>(
      `/api/v1/employees/${employeeId}/positions/search`,
      {
        page_number: pageNumber,
      }
    );
  },
  /*
   * Api could be called without query
   */
  login: async function (email: string, password: string) {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    return api.post<ApiAuthorization>("/api/v1/employees/login", formData);
  },
  /*
   * Api could be called without query
   */
  signUp: async function (formData: ApiUserSignupPayload) {
    return api.post<ApiAuthorization>("/api/v1/employees/signup", formData);
  },
  /*
   * Api could be called without query
   */
  forgotPassword: async function (email: string) {
    return api.post("/api/v1/employees/forgot-password", { email });
  },
  /*
   * Api could be called without query
   */
  resetPassword: async function (token: string) {
    return api.post<ApiAuthorization>("/api/v1/employees/reset-password", { token });
  },
  /*
   * Api could be called without query
   */
  changePassword: async function (password: string) {
    return api.post<ApiAuthorization>("/api/v1/employees/change-password", { password });
  },
  /*
   * Api could be called without query
   */
  resentInviteEmail: async function (employeeId: number) {
    return api.post(`/api/v1/employees/${employeeId}/resend-invite-email`, {});
  },
};
