import { Editor } from "@tiptap/react";
import Box from "../box/Box.tsx";
import Stack from "../stack/Stack.tsx";
import ButtonIcon from "../button/ButtonIcon.tsx";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { useCallback } from "react";
import { ApiEmailTemplate } from "../../types/email-template.types.ts";
import DropdownMenu from "../dropdown-menu/DropdownMenu.tsx";
import { SelectOption } from "../select/SelectOption.tsx";
import Button from "../button/Button.tsx";
import { getEmailPlaceholderOptions } from "./rich-editor.types.ts";
import { useTranslation } from "react-i18next";

type ToolbarProps = {
  editor: Editor;
  emailTemplates?: ApiEmailTemplate[];
  onApplyTemplate?: (template: ApiEmailTemplate) => void;
};

export const RichEditorToolbar = ({ editor, emailTemplates, onApplyTemplate }: ToolbarProps) => {
  const { t } = useTranslation();

  const emailPlaceholderOptions = getEmailPlaceholderOptions(t);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  const applyTemplate = useCallback(
    (template: ApiEmailTemplate) => {
      editor.commands.setContent(template.body);
      onApplyTemplate?.(template);
    },
    [editor, onApplyTemplate]
  );
  const insertPlaceholder = useCallback(
    (placeholder: string) => {
      editor.chain().focus().insertContent(placeholder).run();
    },
    [editor]
  );

  return (
    <Box className={"border-b border-dark/5 px-3 py-3"}>
      <Stack direction={"horizontal"} gap={"lg"}>
        <Stack direction={"horizontal"} gap={"xs"}>
          <ButtonIcon
            variant={editor.isActive("heading", { level: 2 }) ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-h2"} />}
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          />
          <ButtonIcon
            variant={editor.isActive("heading", { level: 3 }) ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-h3"} />}
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          />
          <ButtonIcon
            variant={editor.isActive("heading", { level: 4 }) ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-h4"} />}
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          />
        </Stack>
        <Stack direction={"horizontal"} gap={"xs"}>
          <ButtonIcon
            variant={editor.isActive("bold") ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-bold"} />}
            onClick={() => editor.chain().focus().toggleBold().run()}
          />
          <ButtonIcon
            variant={editor.isActive("italic") ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-italic"} />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          />
          <ButtonIcon
            variant={editor.isActive("underline") ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-underline"} />}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          />
        </Stack>
        <Stack direction={"horizontal"} gap={"xs"}>
          <ButtonIcon
            variant={editor.isActive("bulletList") ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-list"} />}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          />
          <ButtonIcon
            variant={editor.isActive("orderedList") ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-list-ol"} />}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          />
        </Stack>
        <Stack direction={"horizontal"} gap={"xs"}>
          <ButtonIcon
            variant={editor.isActive("link") ? "secondary" : "plain"}
            icon={<FontAwesomeIcon icon={"fa-light fa-link"} />}
            onClick={() => setLink()}
          />
        </Stack>
        {emailTemplates && emailTemplates.length > 0 && (
          <Stack direction={"horizontal"} gap={"xs"}>
            <DropdownMenu
              trigger={
                <Button
                  className={"font-normal"}
                  variant="plain"
                  leftIcon={<FontAwesomeIcon icon="fa-light fa-pen-field" />}
                  title="Email templates"
                >
                  {t("Templates")}
                </Button>
              }
            >
              {emailTemplates.map((template) => (
                <SelectOption
                  key={`email-template-${template.id}`}
                  item={{
                    id: template.id,
                    title: template.title,
                    item: template,
                    selected: template.body === editor.getHTML(),
                  }}
                  isMulti={false}
                  handleSelect={() => applyTemplate(template)}
                />
              ))}
            </DropdownMenu>
            <DropdownMenu
              trigger={
                <Button
                  className={"font-normal"}
                  variant="plain"
                  leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
                  title="Placeholders"
                >
                  {t("Insert...")}
                </Button>
              }
            >
              {emailPlaceholderOptions.map((placeholder) => (
                <SelectOption
                  key={`placeholder-${placeholder.id}`}
                  item={{
                    id: placeholder.id,
                    title: placeholder.title,
                    item: placeholder,
                    selected: false,
                  }}
                  isMulti={false}
                  handleSelect={() => insertPlaceholder(placeholder.value)}
                />
              ))}
            </DropdownMenu>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
