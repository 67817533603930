import { SelectItem } from "../ui/select/select.types.ts";

export interface ApiRoleShort {
  id: number;
  title: string;
  description: string;
  employees_count: number;
  type: RoleType;
}

export interface ApiRole extends ApiRoleShort {
  api_permissions: ApiPermissions;
  assign_to_list: RoleApplyRule[];
  apply_to_list: RoleApplyRule[];
  employee_permissions: EmployeePermissions;
}

export interface ApiRoleSavePayload {
  title: string;
  description: string;
  api_permissions: ApiPermissions;
  assign_to_list: RoleApplyRuleShort[];
  apply_to_list: RoleApplyRuleShort[];
  employee_permissions: EmployeePermissions;
}

export enum PermissionType {
  none = "none",
  view = "view",
  edit = "edit",
}

export enum Permissions {
  // General
  add_employees = "add_employees",
  dismiss_employees = "dismiss_employees",
  see_dismissed_employees = "see_dismissed_employees",
  see_organization_structure = "see_organization_structure",
  see_departments_structure = "see_departments_structure",
  see_notes = "see_notes",

  // Recruit
  recruiting_module = "recruiting_module",
  show_all_jobs = "show_all_jobs",
  manage_jobs = "manage_jobs",
  show_jobs_salaries = "show_jobs_salaries",
  show_all_candidates = "show_all_candidates",
  manage_candidates = "manage_candidates",
  delete_candidates = "delete_candidates",
  show_candidates_salaries = "show_candidates_salaries",
  delete_all_comments = "delete_all_comments",
  reporting_recruiting_stage_flow = "reporting_recruiting_stage_flow",
  reporting_recruiting_source_efficiency = "reporting_recruiting_source_efficiency",
  reporting_recruiting_closing_time = "reporting_recruiting_closing_time",
  reporting_recruiting_disqualification_reasons = "reporting_recruiting_disqualification_reasons",
  reporting_export = "reporting_export",

  // Settings
  settings_departments = "settings_departments",
  settings_divisions = "settings_divisions",
  settings_positions_levels = "settings_positions_levels",
  settings_roles_permissions = "settings_roles_permissions",
  settings_organization = "settings_organization",
  settings_email_templates = "settings_email_templates",
  settings_pipelines = "settings_pipelines",
  settings_sources = "settings_sources",
  settings_candidate_disqualification_reasons = "settings_candidate_disqualification_reasons",
  settings_locations = "settings_locations",
  settings_legal_entities = "settings_legal_entities",
}

export type PermissionsKeys = keyof typeof Permissions;
type PermissionsKeysFields = {
  [key in PermissionsKeys]: PermissionType;
};

export interface ApiPermissions extends PermissionsKeysFields {}

export enum RoleAssignToConditionType {
  organization = "organization",
  division = "division",
  department = "department",
  // team
  position = "position",
  employee = "employee",
  legal_entity = "legal_entity",
  location = "location",

  // only for apply to
  my_departments = "my_departments",
  my_team = "my_team",
  myself = "myself",

  // only for assign to
  heads_of_departments = "heads_of_departments",
  heads_of_teams = "heads_of_teams",
}

export enum RoleApplySignType {
  include = 1,
  exclude = 2,
}

export enum RoleType {
  admin = "admin",
  manager = "manager",
  team_lead = "team_lead",
  all_employees = "all_employees",
  myself = "myself",
  custom = "custom",
}

export interface RoleApplyRuleShort {
  condition_type: RoleAssignToConditionType;
  sign: RoleApplySignType;
  condition_ids: number[];
}

export interface RoleApplyRule extends RoleApplyRuleShort {
  condition_entities: SelectItem[];
}

export enum EmployeeFieldPermissions {
  employee_name = "employee_name",
  employee_email = "employee_email",
  employee_date_birth = "employee_date_birth",
  employee_gender = "employee_gender",
  employee_hired_on = "employee_hired_on",
  employee_phone_personal = "employee_phone_personal",
  employee_phone = "employee_phone",
  employee_email_personal = "employee_email_personal",
  employee_avatar = "employee_avatar",
  employee_background = "employee_background",

  employee_number = "employee_number",
  employee_address_address_line_1 = "employee_address_address_line_1",
  employee_address_address_line_2 = "employee_address_address_line_2",
  employee_address_country = "employee_address_country",
  employee_address_state = "employee_address_state",
  employee_address_city = "employee_address_city",
  employee_address_zip_code = "employee_address_zip_code",

  employee_salary = "employee_salary",
  employee_salary_description = "employee_salary_description",

  employee_position_job_title = "employee_position_job_title",
  employee_position_date = "employee_position_date",
  employee_position_reporting_to = "employee_position_reporting_to",
  employee_position_division = "employee_position_division",
  employee_position_department = "employee_position_department",
  employee_position_location = "employee_position_location",
  employee_position_legal_entity = "employee_position_legal_entity",
  employee_position_comment = "employee_position_comment",
  employee_documents = "employee_documents",
}

export interface RoleGroup {
  roles: ApiRoleShort[];
  title: string;
  description: string;
  type: RoleGroupType;
}

export enum RoleGroupType {
  base = "base",
  system = "system",
  custom = "custom",
}

export type EmployeeFieldPermissionsKeys = keyof typeof EmployeeFieldPermissions;

type EmployeeFieldPermissionsKeysFields = {
  [key in EmployeeFieldPermissionsKeys]: PermissionType;
};

export interface EmployeePermissions extends EmployeeFieldPermissionsKeysFields {}
