import { api } from "../axios.ts";
import {
  ApiEmailIntegration,
  ApiIntegrationLoginUrl,
} from "../../types/integrations/email.types.ts";

export const apiEmails = {
  disconnectEmailIntegration: (email: string) => {
    return api.delete(`/api/v1/email/disconnect/${encodeURIComponent(email)}`);
  },
  /*
   * Api could be called without query.  This function is used to get the login URL for the email integration
   */
  getIntegrationEmailLoginUrl: () => {
    return api.get<ApiIntegrationLoginUrl>(`/api/v1/email/login`);
  },
  checkEmailIntegration: () => {
    return api.get<ApiEmailIntegration[]>(`/api/v1/email/check`);
  },
  /*
   * Api could be called without query
   */
  readEmail: (emailId: number | string) => {
    return api.put(`/api/v1/email/read/${emailId}`, {});
  },
};
