import { useMemo } from "react";
import { ColumnDef, createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import Text from "../../../../ui/typography/Text.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Box from "../../../../ui/box/Box.tsx";
import ButtonLink from "../../../../ui/button/ButtonLink.tsx";
import {
  ApiAnalyticsRecruitingSourceEfficiencyReport,
  ApiAnalyticsRecruitingSourceEfficiencyReportRowItem,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";
import { useTranslation } from "react-i18next";
import Table from "../../../../ui/table/Table.tsx";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import { ApiPipelineStage } from "../../../../types/recruit/stages.types.ts";

export interface RecruitingSourceEfficiencyReportTableProps {
  report: ApiAnalyticsRecruitingSourceEfficiencyReport | undefined;
  isLoading: boolean;
}

export interface VMRecruitingSourceEfficiencyReportRowItem {
  item: ApiAnalyticsRecruitingSourceEfficiencyReportRowItem;
  width: number;
}

export interface VMRecruitingSourceEfficiencyReportRow {
  sources: VMRecruitingSourceEfficiencyReportRowItem[];
  pipeline_stage: ApiPipelineStage;
  total: number;
  width: number;
  applicants_ids: number[];
}

export const RecruitingSourceEfficiencyReportTable = ({
  report,
  isLoading,
}: RecruitingSourceEfficiencyReportTableProps) => {
  const { handlePreviewApplicants } = useRecruitingReports();
  const { t } = useTranslation();

  const calculatedReportRows = useMemo(() => {
    if (!report) return [];

    const rows: VMRecruitingSourceEfficiencyReportRow[] = [];
    const dayNumbers = report.report.rows.flatMap((e) => e.sources).map((item) => item.value);

    const sums = report.report.rows.map((row) =>
      row.sources.reduce((sum, source) => sum + source.value, 0)
    );
    const maxSumPerSources = Math.max(...sums, 1);

    if (dayNumbers.length == 0) return [];
    const widthPerDay = Math.round(80 / maxSumPerSources);

    for (let i = 0; i < report.report.rows.length; i++) {
      const row = report.report.rows[i];

      const rowItems: VMRecruitingSourceEfficiencyReportRowItem[] = [];

      for (let j = 0; j < report.config.sources.length; j++) {
        const sourceConfig = report.config.sources[j];
        const sourceItem = row.sources.find((e) => e.source.id == sourceConfig.id);
        if (sourceItem) {
          rowItems.push({
            item: sourceItem,
            width: sourceItem.value > 0 ? Math.round(sourceItem.value * widthPerDay) + 10 : 2,
          });
        }
      }

      const total = row.sources.reduce((acc, item) => acc + item.value, 0);
      const applicantIds = row.sources.flatMap((e) => e.applicants_ids);
      rows.push({
        sources: rowItems,
        pipeline_stage: row.pipeline_stage,
        total: total,
        width: total > 0 ? Math.round(total * widthPerDay) + 10 : 2,
        applicants_ids: applicantIds,
      });
    }

    return rows;
  }, [report]);

  const columnHelper = createColumnHelper<VMRecruitingSourceEfficiencyReportRow>();
  const columns = useMemo<ColumnDef<VMRecruitingSourceEfficiencyReportRow>[]>(() => {
    const inner: DisplayColumnDef<VMRecruitingSourceEfficiencyReportRow, unknown>[] = [];
    inner.push(
      columnHelper.display({
        id: "pipeline_stage",
        header: t(""),
        cell: (cell) => (
          <Text className={"text-default"}>{cell.row.original.pipeline_stage.title}</Text>
        ),
        size: 300,
      })
    );

    inner.push(
      columnHelper.display({
        id: `stage-total`,
        header: () => <Text>{t("Total")}</Text>,
        cell: (cell) => (
          <>
            {cell.row.original.total > 0 && (
              <Stack direction={"horizontal"} gap={"sm"}>
                <Box
                  style={{ width: cell.row.original.width }}
                  className={"bg-brand-dark h-5 rounded-r-md"}
                ></Box>
                <ButtonLink
                  variant={"secondary"}
                  onClick={() => {
                    handlePreviewApplicants(
                      cell.row.original.applicants_ids,
                      AnalyticReportTypes.recruitingSourceEfficiency
                    );
                  }}
                >
                  {cell.row.original.total}
                </ButtonLink>
              </Stack>
            )}
          </>
        ),
        enableResizing: false,
        size: "auto" as unknown as number,
      })
    );

    if (report?.config && report.config.sources.length > 0) {
      for (let i = 0; i < report.config.sources.length; i++) {
        const source = report.config.sources[i];
        inner.push(
          columnHelper.display({
            id: `stage-${i}`,
            header: () => <Text className={"capitalize"}>{source.title}</Text>,
            cell: (cell) => (
              <>
                {cell.row.original.sources[i].item ? (
                  <Stack direction={"horizontal"} gap={"sm"}>
                    <Box
                      style={{ width: cell.row.original.sources[i].width }}
                      className={"bg-brand-dark/80 h-5 rounded-r-md"}
                    ></Box>
                    <ButtonLink
                      variant={"secondary"}
                      className={"text-default"}
                      onClick={() => {
                        handlePreviewApplicants(
                          cell.row.original.sources[i].item?.applicants_ids || [],
                          AnalyticReportTypes.recruitingSourceEfficiency
                        );
                      }}
                    >
                      {cell.row.original.sources[i].item?.value}
                    </ButtonLink>
                  </Stack>
                ) : (
                  <Stack direction={"horizontal"} gap={"sm"}>
                    <Box style={{ width: 2 }} className={"bg-brand-dark/80 h-5 rounded-r-md"}></Box>
                    <Text className={"text-secondary"}>-</Text>
                  </Stack>
                )}
              </>
            ),
            enableResizing: false,
            size: "auto" as unknown as number,
          })
        );
      }
    }
    return inner;
  }, [report, calculatedReportRows]);

  return <Table data={calculatedReportRows || []} columns={columns} showSkeleton={isLoading} />;
};
