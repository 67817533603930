import { SelectItem } from "../ui/select/select.types";
import { EmployeeFullNameRenderType } from "./employees/employee.types.ts";
import i18n from "../i18n.ts";

export enum Languages {
  english = "en",
  russian = "ru",
}

export enum Dates {
  yyyy_mm_dd = "yyyy_mm_dd",
  mm_dd_yyyy = "mm_dd_yyyy",
  dd_mm_yyyy = "dd_mm_yyyy",
  dd_mm_yyyy_dot = "dd.mm.yyyy",
}

export interface LanguageItem extends SelectItem {}

export interface NameFormatItem extends SelectItem {}

export interface DateFormatItem extends SelectItem {}

export interface ApiOrganizationSettings {
  title: string;
  language?: Languages;
  timezone?: string;
  employee_name_format?: EmployeeFullNameRenderType;
  default_date_format?: Dates;
  // subdomain: string;
  logo_url?: string;
}

export const LanguageItems: LanguageItem[] = [
  {
    id: Languages.english,
    title: "english",
  },
  {
    id: Languages.russian,
    title: "russian",
  },
];

export const NameFormats: NameFormatItem[] = [
  {
    id: EmployeeFullNameRenderType.first_last,
    title: "first_last",
  },
  {
    id: EmployeeFullNameRenderType.last_first,
    title: "last_first",
  },
  {
    id: EmployeeFullNameRenderType.f_last,
    title: "f_last",
  },
];

export const DateFormats: DateFormatItem[] = [
  {
    id: Dates.dd_mm_yyyy,
    title: "dd_mm_yyyy",
  },
  {
    id: Dates.mm_dd_yyyy,
    title: "mm_dd_yyyy",
  },
  {
    id: Dates.yyyy_mm_dd,
    title: "yyyy_mm_dd",
  },
  {
    id: Dates.dd_mm_yyyy_dot,
    title: "dd_mm_yyyy_dot",
  },
];

export interface ApiOrganizationSettingsSavePayload extends ApiOrganizationSettings {}

export const LanguageOptions = LanguageItems.map((item) => {
  return {
    id: item.id,
    title: i18n.t(item.title),
  };
});
export const NameFormatOptions = NameFormats.map((item) => {
  return {
    id: item.id,
    title: i18n.t(item.title),
  };
});
export const DateFormatOptions = DateFormats.map((item) => {
  return {
    id: item.id,
    title: i18n.t(item.title),
  };
});
