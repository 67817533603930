// import { ApiFile } from "./common.types.ts";
//

import i18n from "../i18n.ts";
import { SelectItem } from "../ui/select/select.types.ts";
import { ApiEmployeeShort } from "./employees/employee.types.ts";

export enum ApiEventTypes {
  interview = "interview",
  other = "other",
}

export interface ApiEventInterview {
  cv_id?: number;
  hide_salary: boolean;
  is_invite_candidate: boolean;
}

export interface ApiEventBase {
  type: ApiEventTypes;
  title: string;

  date_event: Date;
  time_start: Date; //"15:00" https://skailer.atlassian.net/wiki/spaces/Product/pages/6324246
  duration_minutes: number; // minutes
  timezone: string; //"Africa/Abidjan", // формат тут https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  comment?: string;

  location: string;
  is_private: boolean;
  is_cancelled: boolean;

  interview?: ApiEventInterview;
}

export interface ApiEvent extends ApiEventBase {
  guests: ApiEmployeeShort[];
  owner?: ApiEmployeeShort;
  id: number;
}

export interface ApiEventWithOptionalId extends Omit<ApiEvent, "id"> {
  id?: number;
}

export interface ApiEventFilter {
  text?: string;
  employee_ids?: [number];
  date_start?: Date;
  date_end?: Date;
  types?: ApiEventTypes[];
}

export interface ApiEventPayload extends ApiEventBase {
  guest_ids: number[]; // другие сотрудники как гости
}

export const getDurations = () => {
  return [
    {
      id: 15,
      title: i18n.t("plural_minutes", { count: 15 }),
    },
    {
      id: 30,
      title: i18n.t("plural_minutes", { count: 30 }),
    },
    {
      id: 60,
      title: i18n.t("plural_hours", { count: 1 }),
    },
    {
      id: 90,
      title: `${i18n.t("plural_hours", { count: 1 })} ${i18n.t("plural_minutes", { count: 30 })}`,
    },
  ] as SelectItem[];
};

export const getStartTimesSelected = () => {
  const startTimes: SelectItem[] = [];
  for (let i = 9; i < 20; i++) {
    startTimes.push({
      id: i,
      title: `${i}:00`,
    });
    startTimes.push({
      id: i + 0.5,
      title: `${i}:30`,
    });
  }
  return startTimes;
};
