import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "../../../../ui/table/Table.tsx";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../components/empty-state/EmptyState.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { Page } from "../../../../components/page/Page.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { useEffectOnce } from "react-use";
import { EmailTemplatesEditModal } from "./widgets/EmailTemplatesEditModal.tsx";
import { ApiEmailTemplate } from "../../../../types/email-template.types.ts";
import {
  useEmailTemplateDelete,
  useEmailTemplates,
} from "../../../../queries/use-email-templates.query.ts";

export default function EmailTemplates() {
  const { t } = useTranslation();

  const modalsService = useModals();
  const { data: emailTemplates, isLoading, isFetching } = useEmailTemplates();
  const emailTemplateDelete = useEmailTemplateDelete();

  const handleEditOrCreate = useCallback(
    (emailTemplate: ApiEmailTemplate | undefined = undefined) => {
      const emailTemplateToEdit = emailTemplate || {
        id: 0,
        title: "",
        subject: "",
        body: "",
        is_default: false,
      };

      modalsService.openModal(EmailTemplatesEditModal, {
        emailTemplateId: emailTemplateToEdit.id,
        emailTemplate: emailTemplateToEdit,
      });
    },
    []
  );

  const handleDelete = useCallback((emailTemplate: ApiEmailTemplate) => {
    emailTemplateDelete.mutate(emailTemplate.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.emailTemplates.deleted, {
          [analyticProperties.id]: emailTemplate.id,
        });
      },
    });
  }, []);

  const columnHelper = createColumnHelper<ApiEmailTemplate>();
  const columns = useMemo<ColumnDef<ApiEmailTemplate>[]>(
    () => [
      {
        header: t("Title"),
        cell: (row) => (
          <Stack direction={"horizontal"} className={"items-center"} gap={"sm"}>
            <Text className={"text-title"}>{row.row.original.title}</Text>
          </Stack>
        ),
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      {
        header: t("Subject"),
        cell: (row) => (
          <Stack direction={"horizontal"} className={"items-center"} gap={"sm"}>
            <Text className={"text-title"}>{row.row.original.subject}</Text>
          </Stack>
        ),
        accessorKey: "address",
        size: "auto" as unknown as number,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
              variant={"secondary"}
            >
              {t("Edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleDelete(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("Delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [emailTemplates]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.emailTemplates.viewed);
  });

  return (
    <>
      <Page>
        <Page.Header showBack={true} title={t("Email templates")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Create new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Table
            data={emailTemplates || []}
            columns={columns}
            showSkeleton={isLoading}
            isFetching={isFetching || emailTemplateDelete.isPending}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={<EmptyState />}
          />
        </Page.Content>
      </Page>
    </>
  );
}
