import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import i18n from "../../../../i18n.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import Box from "../../../../ui/box/Box.tsx";

export interface ChoosePeriodModalProps extends ModalProps {
  dateFrom?: Date;
  dateTo?: Date;
  onApply: (dateFrom: Date, dateTo: Date) => void;
}

export function ChoosePeriodModal({ dateFrom, dateTo, onApply, ...props }: ChoosePeriodModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const periodSchema = yup.object({
    dateFrom: yup.date().required(i18n.t("Field is required")),
    dateTo: yup.date().required(i18n.t("Field is required")),
  });

  interface PeriodSchema extends yup.InferType<typeof periodSchema> {}

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PeriodSchema>({
    mode: "onSubmit",
    resolver: yupResolver(periodSchema),
    defaultValues: {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });

  const onSubmit = handleSubmit((schemaData) => {
    onApply(schemaData.dateFrom, schemaData.dateTo);
    close(id);
  });

  return (
    <>
      <Modal
        {...props}
        title={t("Period")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <Button type={"submit"} size={"lg"} onClick={onSubmit}>
              {t("Save")}
            </Button>
            <Button
              type={"submit"}
              size={"lg"}
              variant={"plain"}
              onClick={() => {
                close(id);
              }}
            >
              {t("Cancel")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Box className={"grid grid-cols-2 gap-sm"}>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Datepicker
                  value={value}
                  onChange={onChange}
                  label={t("Date from")}
                  error={errors.dateFrom?.message}
                />
              )}
              control={control}
              name={"dateFrom"}
            />{" "}
            <Controller
              render={({ field: { value, onChange } }) => (
                <Datepicker
                  value={value}
                  onChange={onChange}
                  label={t("Date to")}
                  error={errors.dateTo?.message}
                />
              )}
              control={control}
              name={"dateTo"}
            />
          </Box>
        </form>
      </Modal>
    </>
  );
}
