import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Multiselect from "../../../../ui/select/Multiselect.tsx";
import { useSearchParams } from "react-router-dom";
import { useJobByIds, useJobsSearch } from "../../../../queries/recruit/use-jobs.query.ts";
import { useMemo, useState } from "react";
import {
  ApiJobsFilter,
  ApiJobsFilterDefault,
  JobStatus,
} from "../../../../types/recruit/job.types.ts";
import { ApiAnalyticsRecruitingFilter } from "../../../../types/analytics/analytics.recruiting.types.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import useEmployeeSearchInline, {
  useEmployeesByIds,
} from "../../../../queries/employees/use-employees.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { SelectItem } from "../../../../ui/select/select.types.ts";

export interface RecruitingReportFilterModalProps extends ModalProps {
  filter: ApiAnalyticsRecruitingFilter;
  reportType: AnalyticReportTypes;
}

export function RecruitingReportFilterModal({
  filter,
  reportType,
  ...props
}: RecruitingReportFilterModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { renderEmployeeName } = useFormatter();

  const [jobsFilter, setJobsFilter] = useState<ApiJobsFilter>({
    ...ApiJobsFilterDefault,
    status_list: [JobStatus.published],
    my_jobs: true,
  });
  const { data: jobsSearched, isLoading: jobsIsLoading } = useJobsSearch(jobsFilter);
  const { data: jobsSelected } = useJobByIds(filter.job_ids || []);

  async function handleSearchJobs(text: string | undefined) {
    setJobsFilter({
      ...jobsFilter,
      text: text,
    });
  }

  const recruitersSearch = useEmployeeSearchInline();
  const { data: recruitersOptions } = useEmployeesByIds(filter.recruiter_ids || []);
  const recruitersOptionsTransformed: SelectItem[] = useMemo(() => {
    if (!recruitersOptions) return [];

    return recruitersOptions.map((recruiter) => {
      return {
        id: recruiter.id,
        title: renderEmployeeName(recruiter),
      };
    });
  }, [recruitersOptions]);

  const FilterSchemaObject = yup.object({
    job_ids: yup.array().of(yup.number().required()).required(),
    recruiter_ids: yup.array().of(yup.number().required()).required(),
  });

  interface FilterSchema extends yup.InferType<typeof FilterSchemaObject> {}

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FilterSchema>({
    mode: "onSubmit",
    resolver: yupResolver(FilterSchemaObject),
    defaultValues: {
      job_ids: filter.job_ids || [],
      recruiter_ids: filter.recruiter_ids || [],
    },
  });

  const onSubmit = handleSubmit((filterSchema) => {
    if (filterSchema.job_ids.length > 0)
      searchParams.set("job_ids", filterSchema.job_ids.join(","));
    if (filterSchema.recruiter_ids.length > 0)
      searchParams.set("recruiter_ids", filterSchema.recruiter_ids.join(","));

    analyticsService.trackEvent(analyticEvents.reporting.filtered, {
      [analyticProperties.title]: reportType,
    });
    setSearchParams(searchParams);

    close(id);
  });

  function handleClearFilters() {
    searchParams.delete("job_ids");
    searchParams.delete("recruiter_ids");
    setSearchParams(searchParams);
    close(id);
  }

  return (
    <>
      <Modal
        {...props}
        variant={"side"}
        title={t("Filter")}
        layout={"stretch"}
        withCloser
        closeByEscEnabled
        actions={
          <Stack gap={"sm"}>
            <Button size={"lg"} className={"w-full"} type={"submit"} onClick={onSubmit}>
              {t("Apply filter")}
            </Button>
            <Button
              size={"lg"}
              variant={"plain"}
              onClick={() => {
                handleClearFilters();
              }}
            >
              {t("Clear all filters")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Multiselect
                label={t("Recruiters")}
                emptyTitle={t("Select")}
                isLoading={recruitersSearch.isLoading}
                values={value}
                searchable
                options={recruitersOptionsTransformed}
                onSearchOptions={recruitersSearch.search}
                loadOptionsOnOpen
                searchOptions={recruitersSearch.options}
                onChange={(value) => {
                  onChange(value);
                }}
                error={errors.recruiter_ids?.message}
              />
            )}
            control={control}
            name={"recruiter_ids"}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Multiselect
                label={t("Job")}
                emptyTitle={t("Select")}
                isLoading={jobsIsLoading}
                values={value}
                searchable
                options={jobsSelected}
                onSearchOptions={handleSearchJobs}
                loadOptionsOnOpen
                searchOptions={jobsSearched?.items}
                onChange={(value) => {
                  onChange(value);
                }}
                error={errors.job_ids?.message}
              />
            )}
            control={control}
            name={"job_ids"}
          />
        </form>
      </Modal>
    </>
  );
}
