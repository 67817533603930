import { ApiEmployeeShort, EmployeeFullNameRenderType } from "../types/employees/employee.types.ts";
import { useEmployeeStore } from "../stores/employee.store.ts";
import { format, intervalToDuration, isToday, isYesterday } from "date-fns";
import i18n from "../i18n.ts";
import { enUS, ru } from "date-fns/locale";
import { ApiPartialDate } from "../types/common.types.ts";
import { useTranslation } from "react-i18next";
import { ApiAnalyticsAggregationDate } from "../types/analytics/analytics.recruiting.types.ts";
import { ApiLocation } from "../types/location.types.ts";

function getWeekNumber(date: Date): number {
  // Copying date so the original date won't be modified
  const tempDate = new Date(date.valueOf());

  // ISO week date weeks start on Monday, so correct the day number
  const dayNum = (date.getDay() + 6) % 7;

  // Set the target to the nearest Thursday (current date + 4 - current day number)
  tempDate.setDate(tempDate.getDate() - dayNum + 3);

  // ISO 8601 week number of the year for this date
  const firstThursday = tempDate.valueOf();

  // Set the target to the first day of the year
  // First set the target to January 1st
  tempDate.setMonth(0, 1);

  // If this is not a Thursday, set the target to the next Thursday
  if (tempDate.getDay() !== 4) {
    tempDate.setMonth(0, 1 + ((4 - tempDate.getDay() + 7) % 7));
  }

  // The weeknumber is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week
  return 1 + Math.ceil((firstThursday - tempDate.valueOf()) / 604800000); // 604800000 = number of milliseconds in a week
}

export default function useFormatter() {
  const { employee } = useEmployeeStore();
  const { t } = useTranslation();

  function _getLocale() {
    if (employee?.language == undefined) {
      return enUS;
    }

    if (employee.language == "ru") return ru;
    return enUS;
  }

  function renderEmployeeName(emp: ApiEmployeeShort) {
    const formatter: EmployeeFullNameRenderType =
      employee && employee.id % 2 == 0
        ? EmployeeFullNameRenderType.first_last
        : EmployeeFullNameRenderType.last_first;

    switch (formatter) {
      case EmployeeFullNameRenderType.first_last:
        return `${emp.first_name} ${emp.last_name}`;
      case EmployeeFullNameRenderType.last_first:
        return `${emp.last_name} ${emp.first_name}`;
    }
  }

  function renderDateAndTime(date: Date) {
    return format(date, "Pp", { locale: _getLocale() });
  }

  function renderDate(date: Date) {
    return format(date, "PP", { locale: _getLocale() });
  }

  function renderTime(date: Date) {
    return format(date, "p", { locale: _getLocale() });
  }

  function formatPeriodInReportingGroups(
    dateStart: Date,
    dateEnd: Date,
    grouping?: ApiAnalyticsAggregationDate
  ): string[] {
    // if (!dateStart && !dateEnd) return t("All time");

    // if (!dateEnd) return "C"; // todo
    // if (!dateStart) return "po"; // todo

    const quarter = Math.floor((dateStart.getMonth() + 3) / 3);

    switch (grouping) {
      case ApiAnalyticsAggregationDate.day:
        return [
          `${capitalizeFirstLetter(format(dateStart, "cccc", { locale: _getLocale() }))}`,
          `${format(dateStart, "dd MMMM", { locale: _getLocale() })}`,
        ];
      case ApiAnalyticsAggregationDate.week:
        return [
          `${t("Week")} ${getWeekNumber(dateStart)}`,
          `${format(dateStart, "dd MMM", { locale: _getLocale() }).replace(
            ".",
            ""
          )} — ${format(dateEnd, "dd MMM", { locale: _getLocale() })}`.replace(".", ""),
        ];
      case ApiAnalyticsAggregationDate.month:
        return [
          `${capitalizeFirstLetter(format(dateStart, "LLLL", { locale: _getLocale() }))}`,
          `${format(dateStart, "dd MMM", { locale: _getLocale() }).replace(
            ".",
            ""
          )} — ${format(dateEnd, "dd MMM", { locale: _getLocale() })}`.replace(".", ""),
        ];
      case ApiAnalyticsAggregationDate.quarter:
        return [
          `Q${quarter}`,
          `${format(dateStart, "dd MMM", { locale: _getLocale() }).replace(
            ".",
            ""
          )} — ${format(dateEnd, "dd MMM", { locale: _getLocale() })}`.replace(".", ""),
        ];
      case ApiAnalyticsAggregationDate.year:
        return [
          `${format(dateStart, "yyyy", { locale: _getLocale() })}`,
          `${format(dateStart, "dd MMM", { locale: _getLocale() }).replace(
            ".",
            ""
          )} — ${format(dateEnd, "dd MMM", { locale: _getLocale() })}`.replace(".", ""),
        ];
      default:
        return [];
    }
  }

  function formatPeriodInReporting(
    dateStart?: Date,
    dateEnd?: Date,
    grouping?: ApiAnalyticsAggregationDate
  ) {
    if (!dateStart && !dateEnd && !grouping) return t("All time");

    if (!dateEnd) return "C"; // todo
    if (!dateStart) return "po"; // todo

    const dateEndAsPeriod = dateEnd; //addDays(dateEnd);
    const needYear = dateStart.getFullYear() !== dateEndAsPeriod.getFullYear();
    if (needYear) {
      return `${format(dateStart, "dd MMM yyyy", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM yyyy", { locale: _getLocale() })}`;
    }

    return `${format(dateStart, "dd MMM", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM", { locale: _getLocale() })}`;

    // if (needYear) {
    //   switch (grouping) {
    //     case ApiAnalyticsAggregationDate.day:
    //       return `${format(dateStart, "dd MMM yyyy", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM yyyy", { locale: _getLocale() })}`;
    //     case ApiAnalyticsAggregationDate.week:
    //       return `${format(dateStart, "dd MMM yyyy", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM yyyy", { locale: _getLocale() })}`;
    //     case ApiAnalyticsAggregationDate.month:
    //       return `${format(dateStart, "dd MMM yyyy", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM yyyy", { locale: _getLocale() })}`;
    //     case ApiAnalyticsAggregationDate.quarter:
    //       return `${format(dateStart, "dd MMM yyyy", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM yyyy", { locale: _getLocale() })}`;
    //     case ApiAnalyticsAggregationDate.year:
    //       return `${format(dateStart, "dd MMM yyyy", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM yyyy", { locale: _getLocale() })}`;
    //   }
    // }
    //
    // switch (grouping) {
    //   case ApiAnalyticsAggregationDate.day:
    //     return `${format(dateStart, "dd MMM", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM", { locale: _getLocale() })}`;
    //   case ApiAnalyticsAggregationDate.week:
    //     return `${format(dateStart, "dd MMM", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "dd MMM", { locale: _getLocale() })}`;
    //   case ApiAnalyticsAggregationDate.month:
    //     return `${format(dateStart, "LLLL", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "LLLL", { locale: _getLocale() })}`;
    //   case ApiAnalyticsAggregationDate.quarter:
    //     return `${format(dateStart, "LLL", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "LLL", { locale: _getLocale() })}`;
    //   case ApiAnalyticsAggregationDate.year:
    //     return `${format(dateStart, "yyyy", { locale: _getLocale() })} — ${format(dateEndAsPeriod, "yyyy", { locale: _getLocale() })}`;
    // }
  }

  function renderRelativeDate(date: Date) {
    if (isToday(date)) {
      return `${i18n.t("Today")}, ${format(date, "p", { locale: _getLocale() })}`;
    }
    if (isYesterday(date)) {
      return `${i18n.t("Yesterday")}, ${format(date, "p", { locale: _getLocale() })}`;
    }
    return renderDate(date);
  }

  function renderPartialDate(date: ApiPartialDate | undefined): string | undefined {
    if (!date) return undefined;

    const birthDate = new Date();

    if (date.month && date.day && date.year) {
      birthDate.setFullYear(date.year);
      birthDate.setMonth(date.month - 1);
      birthDate.setDate(date.day);

      return renderDate(birthDate);
    }

    if (date.month && date.day) {
      return format(new Date(2000, date.month - 1, date.day), "dd MMM", { locale: _getLocale() });
    }

    return undefined;
  }

  function calculateAge(date: ApiPartialDate | undefined): number {
    if (!date) return 0;

    const currentDate = new Date();
    const birthDate = new Date();

    if (!date.year) return 0;

    birthDate.setFullYear(date.year);
    birthDate.setMonth(date.month ? date.month - 1 : 0);

    birthDate.setDate(date.day ?? 1);

    if (birthDate > currentDate) return 0;

    const age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      birthDate.getMonth() > currentDate.getMonth() ||
      (birthDate.getMonth() === currentDate.getMonth() &&
        birthDate.getDate() > currentDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  }

  function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  function formatDate(date: ApiPartialDate): string {
    let result = "";

    if (date.year && date.month && date.day) {
      result += `${capitalizeFirstLetter(format(new Date(date.year, date.month - 1, date.day), "LLLL", { locale: _getLocale() }))} ${date.year}`;
    } else if (date.year && date.month) {
      result += `${capitalizeFirstLetter(format(new Date(date.year, date.month - 1, 1), "LLLL", { locale: _getLocale() }))} ${date.year}`;
    } else if (date.year) {
      result += `${date.year}`;
    }

    return result;
  }

  function formatDuration(from: ApiPartialDate, to: ApiPartialDate | null | undefined): string {
    let fromString = "";
    let toString = "";

    if (from && from.year) {
      fromString = formatDate(from);
    } else {
      fromString = "";
    }

    if (to && to.year) {
      toString = formatDate(to);
    } else {
      toString = t("Present");
    }

    return `${fromString.length > 0 ? `${fromString} - ` : ``}${toString}`;
  }

  function formatExperiencePeriod(
    date_from: ApiPartialDate,
    date_to: ApiPartialDate | undefined
  ): string {
    const duration = formatExperienceDuration(date_from, date_to);

    return duration
      ? `${formatDuration(date_from, date_to)} (${duration})`
      : formatDuration(date_from, date_to);
  }

  function formatExperienceDuration(
    date_from: ApiPartialDate,
    date_to: ApiPartialDate | undefined
  ): string {
    const today = new Date();
    let duration = "";

    if (date_from && date_from.year) {
      const from = new Date(date_from.year || 0, (date_from.month || 0) - 1, date_from.day || 1);
      const to =
        date_to && date_to.year
          ? new Date(date_to.year || 0, (date_to.month || 0) - 1, date_to.day || 1)
          : today;
      const interval = intervalToDuration({
        start: from,
        end: to,
      });

      const durationArray: string[] = [];

      // Считаем, что если он работал 11 месяцев и 5 дней, то это 1 год
      const days = interval.days || 0;
      let months = interval.months ? interval.months + (days > 0 ? 1 : 0) : 0;
      let years = interval.years || 0;
      if (months >= 12) {
        months -= 12;
        years += 1;
      }

      if (years > 0) {
        durationArray.push(i18n.t("plural_year", { count: years }));
      }

      if (months > 0) {
        durationArray.push(i18n.t("plural_month", { count: months }));
      }

      duration = durationArray.join(" ");
    }

    return duration;
  }

  function formatDurationPartialDate(
    date_from: ApiPartialDate,
    date_to: ApiPartialDate | undefined
  ): string {
    const today = new Date();
    let duration = "";

    if (date_from && date_from.year) {
      const from = new Date(date_from.year || 0, (date_from.month || 0) - 1, date_from.day || 1);
      const to =
        date_to && date_to.year
          ? new Date(date_to.year || 0, (date_to.month || 0) - 1, date_to.day || 1)
          : today;
      const interval = intervalToDuration({
        start: from,
        end: to,
      });

      const durationArray: string[] = [];

      // const days = interval.days || 0;
      const months = interval.months || 0;
      const years = interval.years || 0;

      if (years > 0) {
        durationArray.push(i18n.t("plural_year", { count: years }));
      }

      if (months > 0) {
        durationArray.push(i18n.t("plural_month", { count: months }));
      }

      duration = durationArray.join(" ");
    }

    return duration;
  }

  function transformDateToPartialDate(date: Date): ApiPartialDate {
    return {
      day: date.getDay(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  // function getISODate(date: Date) {
  //   return date.toISOString().split("T")[0];
  // }

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  function renderFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + " " + units[u];
  }

  function renderLocationAddress(location: ApiLocation) {
    let address = "";
    if (location.address_1) {
      address += location.address_1 + "\n";
    }
    if (location.address_2) {
      address += location.address_2 + "\n";
    }

    const city = `${location.city || ""} ${location.state || ""} ${location.zip || ""}`.trim();
    if (city) {
      address += city + "\n";
    }

    address += location.country.title;
    return address;
  }

  return {
    calculateAge,
    renderTime,
    renderEmployeeName,
    renderDate,
    formatExperiencePeriod,
    renderPartialDate,
    renderRelativeDate,
    renderDateAndTime,
    renderFileSize,
    formatPeriodInReporting,
    formatPeriodInReportingGroups,
    // formatExperienceDuration,
    transformDateToPartialDate,
    formatDurationPartialDate,
    renderLocationAddress,
  };
}
