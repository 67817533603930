import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const sidebarVisiblePaths = ["/recruitment", "/settings", "/team", "/"];
const sidebarHiddenPaths = ["/employees/chart", "/departments/chart"];

export default function useSidebar() {
  const { pathname } = useLocation();

  const sidebarIsShowing = useMemo(() => {
    const shouldShowSidebar = sidebarVisiblePaths.some((path) => pathname.startsWith(path));
    const shouldHideSidebar = sidebarHiddenPaths.some((path) => pathname === path);
    return shouldShowSidebar && !shouldHideSidebar;
  }, [pathname]);

  return { sidebarIsShowing };
}
