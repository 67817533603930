import { useTranslation } from "react-i18next";
import { Search } from "../../../../../components/search/Search.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { useMemo, useState } from "react";
import Box from "../../../../../ui/box/Box.tsx";
import analyticsService, { analyticEvents } from "../../../../../services/analytics-service.ts";
import {
  ApiEmployeeFilter,
  ApiEmployeeWithRoles,
} from "../../../../../types/employees/employee.types.ts";
import Table from "../../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../../components/empty-state/EmptyState.tsx";
import { ColumnDef } from "@tanstack/react-table";
import Text from "../../../../../ui/typography/Text.tsx";
import { EmployeeInlineCard } from "../../../../../components/employee-inline-card/EmployeeInlineCard.tsx";
import { useDebounce, useEffectOnce } from "react-use";
import { RoleType } from "../../../../../types/role.types.ts";
import { Title } from "../../../../../components/title/Title.tsx";
import { useEmployeesSearchWithRoles } from "../../../../../queries/employees/use-employees.query.ts";
import { PaginationBlock } from "../../../../../ui/pagination/PaginationBlock.tsx";

export default function EmployeesWithRoles() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ApiEmployeeFilter>({
    text: searchTerm,
    page_number: 1,
  });

  useDebounce(
    () => {
      setFilter({
        ...filter,
        page_number: 1,
        text: searchTerm,
      });
    },
    300,
    [searchTerm]
  );

  const { data: items, isLoading, isFetching } = useEmployeesSearchWithRoles(filter);
  const columns = useMemo<ColumnDef<ApiEmployeeWithRoles>[]>(
    () => [
      {
        header: t("Employee"),
        cell: (row) => <EmployeeInlineCard employee={row.row.original.employee} />,
        accessorKey: "title",
      },
      {
        header: t("Position"),
        cell: (row) => (
          <Text className={"text-secondary"}>
            {row.row.original.employee.employee_position?.position?.title}
          </Text>
        ),
        accessorKey: "job_title",
      },
      {
        header: t("Roles"),
        cell: (row) => (
          <Text className={"text-secondary"}>
            {row.row.original.roles
              .filter((role) => [RoleType.all_employees, RoleType.myself].indexOf(role.type) == -1)
              .map((role) => role.title)
              .join(", ")}
          </Text>
        ),
        accessorKey: "roles",
      },
    ],
    []
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.roles.viewedEmployees);
  });

  return (
    <Stack>
      <Title header={t("Check employees roles")} size={"md"} paddingTop paddingBottom />
      <Box>
        <Search
          placeholder={t("Name")}
          value={searchTerm}
          onInput={(e) => {
            setSearchTerm(e.currentTarget.value);
          }}
          type={"text"}
        />
      </Box>
      <Table
        data={items?.items || []}
        columns={columns}
        showSkeleton={isLoading}
        isFetching={isFetching}
        showNotFoundError={filter.text != "" && items?.metadata.total_items == 0}
        notFoundComponent={<EmptyStateNothingFound />}
        emptyStateComponent={<EmptyState />}
      />
      <PaginationBlock
        metadata={items?.metadata}
        onPageChange={(page) => {
          setFilter({ ...filter, page_number: page });
        }}
      />
    </Stack>
  );
}
