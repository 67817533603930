import { TFunction } from "i18next";

export enum EmailPlaceholder {
  CANDIDATE_LAST = "[candidate last]",
  CANDIDATE_FIRST = "[candidate first]",
  CANDIDATE_FULL = "[candidate full]",
  CANDIDATE_EMAIL = "[candidate email]",
  JOB_TITLE = "[job title]",
  COMPANY_NAME = "[company name]",
  SENDER_FIRST = "[sender first]",
  SENDER_LAST = "[sender last]",
  SENDER_FULL = "[sender full]",
  SENDER_EMAIL = "[sender email]",
  SENDER_JOB_TITLE = "[sender job title]",
  SENDER_PHONE = "[sender phone]",
}

export interface EmailPlaceholderOption {
  id: EmailPlaceholder;
  title: string;
  value: EmailPlaceholder;
}

export const getEmailPlaceholderOptions = (t: TFunction): EmailPlaceholderOption[] => [
  {
    id: EmailPlaceholder.CANDIDATE_LAST,
    title: t("Candidate last name"),
    value: EmailPlaceholder.CANDIDATE_LAST,
  },
  {
    id: EmailPlaceholder.CANDIDATE_FIRST,
    title: t("Candidate first name"),
    value: EmailPlaceholder.CANDIDATE_FIRST,
  },
  {
    id: EmailPlaceholder.CANDIDATE_FULL,
    title: t("Candidate full name"),
    value: EmailPlaceholder.CANDIDATE_FULL,
  },
  {
    id: EmailPlaceholder.CANDIDATE_EMAIL,
    title: t("Candidate email"),
    value: EmailPlaceholder.CANDIDATE_EMAIL,
  },
  {
    id: EmailPlaceholder.JOB_TITLE,
    title: t("Job"),
    value: EmailPlaceholder.JOB_TITLE,
  },
  {
    id: EmailPlaceholder.COMPANY_NAME,
    title: t("Company name"),
    value: EmailPlaceholder.COMPANY_NAME,
  },
  {
    id: EmailPlaceholder.SENDER_FIRST,
    title: t("Sender first name"),
    value: EmailPlaceholder.SENDER_FIRST,
  },
  {
    id: EmailPlaceholder.SENDER_LAST,
    title: t("Sender last name"),
    value: EmailPlaceholder.SENDER_LAST,
  },
  {
    id: EmailPlaceholder.SENDER_FULL,
    title: t("Sender full name"),
    value: EmailPlaceholder.SENDER_FULL,
  },
  {
    id: EmailPlaceholder.SENDER_EMAIL,
    title: t("Sender email"),
    value: EmailPlaceholder.SENDER_EMAIL,
  },
  {
    id: EmailPlaceholder.SENDER_JOB_TITLE,
    title: t("Sender job title"),
    value: EmailPlaceholder.SENDER_JOB_TITLE,
  },
  {
    id: EmailPlaceholder.SENDER_PHONE,
    title: t("Sender phone"),
    value: EmailPlaceholder.SENDER_PHONE,
  },
];
