import { useTranslation } from "react-i18next";
import { SidebarHeader } from "../SidebarHeader.tsx";

import { SidebarTeamBaseMenu } from "./SidebarTeamBaseMenu.tsx";

export const SidebarTeam = () => {
  const { t } = useTranslation();

  return (
    <>
      <SidebarHeader title={t("Team")} />
      <SidebarTeamBaseMenu />
    </>
  );
};
