import { ModalLayoutProps } from "../modal.types.ts";
import { ModalHeader } from "../ModalHeader.tsx";
import { useTheme } from "../../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import Box from "../../box/Box.tsx";

export function ModalLayoutBase({
  children,
  id,
  title,
  withCloser,
  style,
  layout = "base",
  refFromModal,
  floatingProps,
  // className,
  actions,
  onBack,
  size = "md",
}: ModalLayoutProps) {
  const theme = useTheme("modal");

  return (
    <>
      <div></div>
      <div
        className={twMerge(
          theme.base,
          theme.variants.layout[layout].content,
          theme.variants.size[size]
        )}
        ref={refFromModal}
        {...floatingProps}
        style={style}
      >
        {title && <ModalHeader id={id} title={title} withCloser={withCloser} onBack={onBack} />}
        <div className={theme.container[size]}>{children}</div>
        {actions && (
          <Box
            style={{ boxShadow: "0px -1px 0px 0px hsla(0, 0%, 0%, 0.04)" }}
            className={theme.variants.layout[layout].actions}
          >
            {actions}
          </Box>
        )}
      </div>
      <div></div>
    </>
  );
}
