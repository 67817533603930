import useModals from "../../../../ui/modal/modal.store.ts";
import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { ApiJobsFilter, ApiJobsFilterDefault } from "../../../../types/recruit/job.types.ts";
import Select from "../../../../ui/select/Select.tsx";
import { ApiCandidateCreatePayload } from "../../../../types/recruit/candidate.types.ts";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ApiApplicantShort } from "../../../../types/recruit/applicant.types.ts";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import {
  ApiPipelineStageShort,
  PipelineStageTypes,
} from "../../../../types/recruit/stages.types.ts";
import { useJobsSearch } from "../../../../queries/recruit/use-jobs.query.ts";
import { useCandidateCreate } from "../../../../queries/recruit/use-candidates.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";

export interface ApplicantAssignToJobModalProps extends ModalProps {
  applicant: ApiApplicantShort;
}

export function ApplicantAssignToJobModal({ applicant, ...props }: ApplicantAssignToJobModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const [jobsFilter, setJobsFilter] = useState<ApiJobsFilter>(ApiJobsFilterDefault);
  const { data: jobsSearched, isLoading: jobsIsLoading } = useJobsSearch(jobsFilter);
  const [pipelineStages, setPipelineStages] = useState<ApiPipelineStageShort[]>([]);

  const ApplicantAssignToJobSchemaObject = yup.object({
    job_id: yup.number().required(i18n.t("Field is required")),
    pipeline_stage_id: yup.number().required(i18n.t("Field is required")),
  });

  interface ApplicantAssignToJobSchema
    extends yup.InferType<typeof ApplicantAssignToJobSchemaObject> {}

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ApplicantAssignToJobSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ApplicantAssignToJobSchemaObject),
  });

  const candidateCreate = useCandidateCreate(control);

  const onSubmit = handleSubmit(async (schema) => {
    const payload: ApiCandidateCreatePayload = {
      job_id: schema.job_id,
      applicant_id: applicant.id,
      pipeline_stage_id: schema.pipeline_stage_id,
      pipeline_id: jobsSearched?.items.find((x) => x.id == schema.job_id)?.pipeline.id || 0,
    };

    candidateCreate.mutate(payload, {
      onSuccess: (candidate) => {
        analyticsService.trackEvent(analyticEvents.applicants.assignedToJob, {
          [analyticProperties.id]: candidate.id,
          [analyticProperties.source]: "App",
        });
        close(id);
      },
    });
  });

  async function handleSearchJobs(text: string | undefined) {
    setJobsFilter({
      ...jobsFilter,
      text: text,
    });
  }

  async function updatePipelineStages(jobId: number) {
    const pipeline = jobsSearched?.items.find((x) => x.id == jobId)?.pipeline;
    if (!pipeline) {
      // todo show error
      return;
    }
    setPipelineStages(pipeline.stages);
    if (pipeline.stages.length > 0) {
      const appliedStage = pipeline.stages.find(
        (stage) => stage.type === PipelineStageTypes.applied
      );

      setValue("pipeline_stage_id", appliedStage ? appliedStage.id : pipeline.stages[0].id);
    }
  }

  return (
    <>
      <Modal
        {...props}
        title={t("Assign to job")}
        withCloser
        closeByEscEnabled
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              isLoading={candidateCreate.isPending}
              disabled={candidateCreate.isPending}
              onClick={onSubmit}
              size={"lg"}
            >
              {t("Save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Job")}
                  emptyTitle={t("Select")}
                  isLoading={jobsIsLoading}
                  value={value}
                  searchable
                  onSearchOptions={handleSearchJobs}
                  loadOptionsOnOpen
                  searchOptions={jobsSearched?.items}
                  onChange={(value) => {
                    onChange(value);
                    updatePipelineStages(value as number);
                  }}
                  error={errors.job_id?.message}
                />
              )}
              control={control}
              name={"job_id"}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("Stage")}
                  emptyTitle={t("Select")}
                  isLoading={jobsIsLoading}
                  value={value}
                  options={pipelineStages}
                  onChange={onChange}
                  error={errors.pipeline_stage_id?.message}
                />
              )}
              control={control}
              name={"pipeline_stage_id"}
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
}
