import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { ApiAnalyticsAggregationDate } from "../../../../types/analytics/analytics.recruiting.types.ts";
import Box from "../../../../ui/box/Box.tsx";
import { useRecruitAnalyticsDisqualificationReasonsReport } from "../../../../queries/recruit/use-recruit-analytics.query.ts";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { RecruitingReportingButtonChoosePeriod } from "../components/RecruitingReportingButtonChoosePeriod.tsx";
import { useSearchParams } from "react-router-dom";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import { DisqualificationReasonsReportTable } from "./DisqualificationReasonsReportTable.tsx";
import { RecruitingReportingRecruitersSelector } from "../components/RecruitingReportingRecruitersSelector.tsx";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";
import { Title } from "../../../../components/title/Title.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { RecruitingReportingButtonFilters } from "../components/RecruitingReportingButtonFilters.tsx";

export const DisqualificationReasonsReport = () => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();

  const { filter } = useRecruitingReports(AnalyticReportTypes.recruitingDisqualificationReasons);
  const { data: report, isLoading } = useRecruitAnalyticsDisqualificationReasonsReport(filter);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.reporting.viewed, {
      [analyticProperties.title]: AnalyticReportTypes.recruitingDisqualificationReasons,
    });
  });

  return (
    <>
      <Box className={"bg-light h-full overflow-y-auto"}>
        <Page.Header title={t("Disqualification reasons report")}>
          <Stack direction={"horizontal"} gap={"sm"} className={"overflow-hidden"}>
            <RecruitingReportingButtonChoosePeriod
              aggregationType={filter.aggregation_date_mode || ApiAnalyticsAggregationDate.month}
              dateFrom={filter.date_start}
              dateTo={filter.date_end}
            />

            <RecruitingReportingButtonFilters
              filter={filter}
              reportType={AnalyticReportTypes.recruitingDisqualificationReasons}
            />
          </Stack>
        </Page.Header>

        <Page.Content>
          <Box>
            <RecruitingReportingRecruitersSelector
              recruiterIds={filter.recruiter_ids || []}
              onChange={(recruiterIds) => {
                setSearchParams((searchParams) => {
                  searchParams.set("recruiter_ids", recruiterIds.join(","));
                  return searchParams;
                });
              }}
            />
          </Box>
          <Title
            header={t("Candidate disqualification analysis")}
            caption={t(
              "Breakdown of reasons for candidate disqualification during the hiring process"
            )}
            paddingTop
            paddingBottom
          />
          <DisqualificationReasonsReportTable report={report} isLoading={isLoading} />
        </Page.Content>
      </Box>
    </>
  );
};
