import { createQueryKeys } from "@lukemorales/query-key-factory";
import { apiApplicants } from "../../api/recruit/applicant.api.ts";
import { ApiErrorException, ApiPassException } from "../../types/api.types.ts";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import {
  ApiApplicantCreatePayload,
  ApiApplicantFilter,
  ApiApplicantSavePayload,
} from "../../types/recruit/applicant.types.ts";
import { Control, FieldValues } from "react-hook-form";
import { jobsKeys } from "./use-jobs.query.ts";
import { candidatesKeys } from "./use-candidates.query.ts";
import { applicantActionsKeys } from "./use-applicant-actions.query.ts";

export const applicantsKeys = createQueryKeys("applicants", {
  search: (filter: ApiApplicantFilter) => ({
    queryKey: [filter],
  }),
  short: (id: number | undefined) => ({
    queryKey: [id],
  }),
  detail: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("No id");
      const result = await apiApplicants.getById(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useApplicantsSearchInfinite = (filter: ApiApplicantFilter) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: applicantsKeys.search(filter).queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const result = await apiApplicants.search({ ...filter, page_number: pageParam }, signal);
      if (result.error) throw new ApiErrorException(result.error);
      result.data?.items.forEach((item) => {
        queryClient.setQueryData(applicantsKeys.short(item.id).queryKey, item);
      });
      return result.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPageParam) =>
      lastPageParam && lastPageParam.metadata.page_number < lastPageParam.metadata.total_pages
        ? lastPageParam.metadata.page_number + 1
        : undefined,
  });
};

export const useApplicantsSearch = (filter: ApiApplicantFilter, enabled: boolean = true) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: applicantsKeys.search(filter).queryKey,
    queryFn: async ({ signal }) => {
      const result = await apiApplicants.search({ ...filter }, signal);
      if (result.error) throw new ApiErrorException(result.error);
      result.data?.items.forEach((item) => {
        queryClient.setQueryData(applicantsKeys.short(item.id).queryKey, item);
      });
      return result.data;
    },
    enabled: enabled,
  });
};

export const useApplicantShortCache = (id: number | undefined) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: applicantsKeys.short(id).queryKey,
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("No id");
      const result = await apiApplicants.search({
        page_number: 1,
        applicant_ids: [id],
      });

      if (result.error) throw new ApiErrorException(result.error);
      if (result.data && result.data.items.length > 0) {
        const applicant = result.data.items[0];
        queryClient.setQueryData(applicantsKeys.short(applicant.id).queryKey, applicant);
        return applicant;
      }
      return undefined;
    },
    staleTime: 60 * 1000,
  });
};
// return queryClient.getQueryData(applicantsKeys.short(id).queryKey) as
//   | ApiApplicantShort
//   | undefined;

export const useApplicantDetails = (id: number | undefined) => {
  return useQuery(applicantsKeys.detail(id));
};

export interface ApplicantSetPhotoParams {
  fileId: number;
  id: number;
}

export const useApplicantSetPhoto = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, fileId }: ApplicantSetPhotoParams) => {
      const result = await apiApplicants.setPhoto(id, fileId);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (applicant, variables) => {
      queryClient.setQueryData(applicantsKeys.detail(variables.id).queryKey, (oldData: unknown) => {
        if (oldData) return { ...oldData, photo_url: applicant.url };
      });
      queryClient.setQueryData(applicantsKeys.short(variables.id).queryKey, (oldData: unknown) => {
        if (oldData) return { ...oldData, photo_url: applicant.url };
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useApplicantDeletePhoto = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiApplicants.deletePhoto(id);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(applicantsKeys.detail(variables).queryKey, (oldData: unknown) => {
        if (oldData) return { ...oldData, photo_url: null };
      });
      queryClient.setQueryData(applicantsKeys.short(variables).queryKey, (oldData: unknown) => {
        if (oldData) return { ...oldData, photo_url: null };
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useApplicantDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiApplicants.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export interface ApplicantsMergeParams {
  applicantId: number;
  similarApplicantId: number;
  resolveType: string;
}

export const useApplicantsMerge = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ applicantId, similarApplicantId, resolveType }: ApplicantsMergeParams) => {
      const result = await apiApplicants.merge(applicantId, similarApplicantId, resolveType);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      // Список апликантов
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      // Список джоб (для каунтеров)
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      // Джоба (для пайплайна)
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
      // Полный апликант
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.detail(variables.applicantId).queryKey,
      });
      // Комменты апликанта
      queryClient.invalidateQueries({
        queryKey: applicantActionsKeys.list(variables.applicantId).queryKey,
      });

      // Обновляем кандидатов текущего апликанта
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId(variables.applicantId).queryKey,
      });
      // Может нужно еще и экшены кандидатов обновить
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useApplicantsUpdateLabels = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (payload: { applicantId: number; labelIds: number[] }) => {
      const result = await apiApplicants.updateLabels(payload.applicantId, payload.labelIds);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        applicantsKeys.detail(variables.applicantId).queryKey,
        (oldData: unknown) => {
          if (oldData && data) return { ...oldData, labels: data.labels };
        }
      );
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useApplicantUpdate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function ({ id, payload }: { id: number; payload: ApiApplicantSavePayload }) {
      const result = await apiApplicants.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.detail(variables.id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useApplicantCreate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function (payload: ApiApplicantCreatePayload) {
      const result = await apiApplicants.create(payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useApplicantCVUpdate = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({
      id,
      payload,
    }: {
      id: number;
      payload: { cv_text: string; cv_id: number };
    }) => {
      const result = await apiApplicants.updateCV(id, payload.cv_text, payload.cv_id);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.detail(variables.id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
