import { Page } from "../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { apiDepartments } from "../../../api/department.api.ts";
import { Chart, ChartNodeData } from "../../../components/chart/Chart.tsx";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import { useSearchParams } from "react-router-dom";
import { ApiDepartment, DepartmentNodeMode } from "../../../types/department.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { DepartmentViewModal } from "../widgets/DepartmentViewModal.tsx";

export function DepartmentsChart() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { renderEmployeeName } = useFormatter();
  const [chart, setChart] = useState<ChartNodeData<ApiDepartment>[]>([]);
  const [searchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<Set<string>>(new Set([]));
  const { openModal } = useModals();
  const loadDepartments = useCallback(async (departmentId: number | undefined) => {
    const { data: departments } = await apiDepartments.chart(departmentId);

    return departments;
  }, []);

  useEffect(() => {
    const initializeChart = async () => {
      const departmentId = searchParams.get("department_id")
        ? Number(searchParams.get("department_id"))
        : undefined;

      if (departmentId) {
        const { data: departments } = await apiDepartments.chart(
          departmentId,
          DepartmentNodeMode.parent
        );
        const opened: string[] = [];
        const current: ApiDepartment | undefined = departments?.find(
          (dep) => dep.id === departmentId
        );

        opened.push(`${departmentId}`);
        current && current.parent_id && opened.push(`${current.parent_id}`);

        setExpanded(new Set(opened));

        departments && appendDepartmentsToChart(departments, true);
      } else {
        // Загрузка организации как корня, если employeeId не указан
        const { data: departments } = await apiDepartments.chart(undefined);

        if (departments) {
          setChart([
            {
              title: employee?.organization?.title ?? t("Organization"),
              img_url: employee?.organization?.logo_url ?? "/skailer-dark.svg",
              id: "root",
              parentId: null,
              count: departments.length,
              isRoot: true,
            },
          ]);

          appendDepartmentsToChart(departments);
        }
      }
    };

    initializeChart();
  }, [searchParams]);

  const renderCaption = (department: ApiDepartment): string => {
    const nameManager = department.manager ? renderEmployeeName(department.manager) : "";
    const otherCount =
      department.employees_count > 1
        ? ` ${t("and_other_count", { count: department.employees_count - (nameManager ? 1 : 0) })}`
        : "";

    return nameManager
      ? `${nameManager}${otherCount}`
      : t("employees_count", { count: department.employees_count });
  };

  const appendDepartmentsToChart = useCallback(
    (departments: ApiDepartment[], batchFromMiddle: boolean = false) => {
      const nodes: ChartNodeData<ApiDepartment>[] = [];
      const ids: Set<number> = new Set(departments.map((dep) => dep.id));

      departments.forEach((department) => {
        let parentId: string | null = department.parent_id ? `${department.parent_id}` : "root";

        if (batchFromMiddle) {
          if (department.parent_id === null) {
            parentId = null;
          }

          if (department.parent_id && !ids.has(department.parent_id)) {
            parentId = null;
          }
        }

        nodes.push({
          title: department.title,
          caption: renderCaption(department),
          img_url: department.manager?.avatar_url ?? null,
          id: `${department.id}`,
          parentId: parentId,
          count: department.direct_subordinates_count,
          isRoot: false,
          original: department,
        });
      });

      setChart((prevChart) => [...prevChart, ...nodes]);
    },
    [chart]
  );

  const onTitleClick = useCallback(
    async (nodeId: string) => {
      if (nodeId == "root") return;

      const node = chart.find((item) => item.id == nodeId);

      console.log(nodeId, node);

      node && node.original && openModal(DepartmentViewModal, { department: node.original });
    },
    [chart]
  );

  const onExpandClick = useCallback(
    (nodeId: string, needLoadData: boolean) => {
      if (nodeId == "root") return;
      if (!needLoadData) return;

      const id = Number(nodeId);

      nodeId &&
        loadDepartments(id).then((departments) => {
          departments && appendDepartmentsToChart(departments);
        });
    },
    [chart]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.departments.viewedChart);
  });

  return (
    <Page className={"bg-light/80 h-full"}>
      <Page.Header showBack={true} title={t("Departments")} />
      {chart.length > 0 && (
        <Chart
          data={chart}
          onExpandClick={onExpandClick}
          onTitleClick={onTitleClick}
          expandedSet={expanded}
        />
      )}
    </Page>
  );
}
