import "./html-viewer.css";

interface HtmlViewerProps {
  content: string;
  type?: "default" | "tooltip";
}

const HtmlViewer = ({ content, type = "default" }: HtmlViewerProps) => {
  const addHtmlPrefix = (html: string) => {
    return html.replace(/<(h[1-6]|p|a|ul|ol|li)(\s|>)/g, '<$1 class="html-$1"$2');
  };
  return (
    <div
      className={`html-content whitespace-pre ${type === "tooltip" ? "html-content-tooltip" : ""}`}
      dangerouslySetInnerHTML={{
        __html: addHtmlPrefix(content),
      }}
    />
  );
};

export default HtmlViewer;
