import { Fragment, useMemo } from "react";
import { VMRecruitingClosingTimeReportRow } from "./RecruitingClosingTimeReport.tsx";
import Box from "../../../../ui/box/Box.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ApiAnalyticsRecruitingClosingTimeReport } from "../../../../types/analytics/analytics.recruiting.types.ts";
import { useTranslation } from "react-i18next";

export interface RecruitingClosingTimeReportTableProps {
  report: ApiAnalyticsRecruitingClosingTimeReport;
}

export const RecruitingClosingTimeReportTable = ({
  report,
}: RecruitingClosingTimeReportTableProps) => {
  const { t } = useTranslation();
  const calculatedReportRows = useMemo(() => {
    if (!report) return [];

    const rows: VMRecruitingClosingTimeReportRow[] = [];
    const dayNumbers = report.report.rows.map((item) => item.value);
    if (dayNumbers.length == 0) return [];
    // const minDays = Math.min(...dayNumbers);
    const maxDays = Math.max(...dayNumbers);
    const widthPerDay = Math.round(110 / Math.max(maxDays, 1));

    for (let i = 0; i < report.report.rows.length; i++) {
      const item = report.report.rows[i];
      rows.push({
        row: item,
        width: item.value > 0 ? Math.round(Math.round(item.value) * widthPerDay) + 10 : 2,
      });
    }

    return rows;
  }, [report]);

  return (
    <Box className={"grid md:grid-cols-12 gap-lg py-lg"}>
      {calculatedReportRows.map((item, index) => (
        <Fragment key={`report-${index}`}>
          <Box className={"md:col-span-3"}>
            <Text className={"text-default"}>{item.row.pipeline_stage.title}</Text>
          </Box>
          <Box className={"md:col-span-9"}>
            <Stack direction={"horizontal"} gap={"sm"}>
              <Box style={{ width: item.width }} className={"bg-brand-dark h-5 rounded-r-md"}></Box>
              <Text>{t("plural_day", { count: Math.round(item.row.value) })}</Text>
            </Stack>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};
