import { ModalProps } from "../../../ui/modal/modal.types.ts";
import {
  ApiEmployee,
  ApiEmployeeEditPayload,
  EmployeeGenders,
} from "../../../types/employees/employee.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "../../../i18n.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../../ui/modal/modal.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import { Input } from "../../../ui/input/Input.tsx";
import { ServerErrorField } from "../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../ui/button/Button.tsx";
import { EmployeeFieldPermissions } from "../../../types/role.types.ts";
import Datepicker from "../../../ui/datepicker/Datepicker.tsx";
import Select from "../../../ui/select/Select.tsx";
import { Title } from "../../../components/title/Title.tsx";
import { useEmployeeSave } from "../../../queries/employees/use-employees.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { useNavigate } from "react-router-dom";
import { pickBy } from "lodash";
import usePermissions from "../../../hooks/use-permissions.hook.ts";
import { useCountries } from "../../../queries/use-locations.query.ts";

export interface EmployeeEditModalProps extends ModalProps {
  employee: ApiEmployee;
}

export function EmployeeEditModal({ employee, ...props }: EmployeeEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canViewField, canEditField, canViewAnyOfFields } = usePermissions();

  const employeeSchema = yup.object({
    last_name: yup.string().required(i18n.t("Field is required")),
    first_name: yup.string().required(i18n.t("Field is required")),
    middle_name: yup.string().nullable(),
    email: yup.string().email(i18n.t("Field should be valid a email")),
    email_personal: yup.string().email(i18n.t("Field should be a valid email")).nullable(),
    phone: yup.string().nullable(),
    phone_personal: yup.string().nullable(),
    date_hired_on: yup.date().required(i18n.t("Field is required")),
    date_birth: yup.date().nullable(),
    gender: yup.string().nullable(),
    personnel_number: yup.string().nullable(),
    address_1: yup.string().nullable(),
    address_2: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    zip: yup.string().nullable(),
    country_id: yup.number().nullable(),
    salary: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value)),
    salary_description: yup.string().nullable(),
  });

  interface EmployeeSchema extends yup.InferType<typeof employeeSchema> {}

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeSchema>({
    mode: "onSubmit",
    resolver: yupResolver(employeeSchema),
    defaultValues: {
      ...employee,
      country_id: employee.country?.id,
    },
  });

  const employeeSave = useEmployeeSave(control);
  const { data: countries } = useCountries(
    canEditField(employee, EmployeeFieldPermissions.employee_address_country)
  );

  const onSubmit = handleSubmit(async (employeeData) => {
    const payload: ApiEmployeeEditPayload = {
      last_name: employeeData.last_name,
      first_name: employeeData.first_name,
      middle_name: employeeData.middle_name,
      email_personal: employeeData.email_personal,
      email: employeeData.email,
      date_hired_on: employeeData.date_hired_on,
      phone_personal: employeeData.phone_personal,
      phone: employeeData.phone,
      date_birth: employeeData.date_birth,
      gender: employeeData.gender,
      personnel_number: employeeData.personnel_number,
      address_1: employeeData.address_1,
      address_2: employeeData.address_2,
      city: employeeData.city,
      state: employeeData.state,
      zip: employeeData.zip,
      country_id: employeeData.country_id,
      salary: employeeData.salary,
      salary_description: employeeData.salary_description,
    };
    const employeePicked = pickBy(payload) as ApiEmployeeEditPayload;

    try {
      const savedEmployee = await employeeSave.mutateAsync({
        id: employee.id,
        payload: employeePicked,
      });

      if (employee.id == 0) {
        analyticsService.trackEvent(analyticEvents.employees.created, {
          [analyticProperties.id]: savedEmployee.id,
        });
      } else {
        analyticsService.trackEvent(analyticEvents.employees.edited, {
          [analyticProperties.id]: employee.id,
          [analyticProperties.actionType]: "Personal Details Edited",
        });
      }

      navigate(`/employees/${savedEmployee.id}`);

      close(id);
    } catch {
      return;
    }
  });

  const genderOptions = EmployeeGenders.map((item) => {
    return {
      id: item.id,
      title: t(item.title),
    };
  });

  return (
    <>
      <Modal
        {...props}
        title={employee.id == 0 ? t("New employee") : t("Edit employee")}
        layout={"stretch"}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={employeeSave.isPending}
              onClick={onSubmit}
            >
              {t("Save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack>
            {canViewField(employee, EmployeeFieldPermissions.employee_name) && (
              <>
                <Input
                  label={t("Last name")}
                  required={true}
                  readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_name)}
                  type={"text"}
                  {...register("last_name", {
                    value: "",
                  })}
                  error={errors.last_name?.message}
                />
                <Input
                  label={t("First name")}
                  required={true}
                  type={"text"}
                  readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_name)}
                  {...register("first_name", {
                    value: "",
                  })}
                  error={errors.first_name?.message}
                />
                <Input
                  label={t("Middle name")}
                  type={"text"}
                  readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_name)}
                  {...register("middle_name", {
                    value: undefined,
                  })}
                  error={errors.middle_name?.message}
                />
              </>
            )}
            {canViewField(employee, EmployeeFieldPermissions.employee_number) && (
              <Input
                label={t("Employee #")}
                type={"text"}
                readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_number)}
                {...register("personnel_number", {
                  value: undefined,
                })}
                error={errors.personnel_number?.message}
              />
            )}
            {canViewField(employee, EmployeeFieldPermissions.employee_email) && (
              <>
                <Title
                  header={t("Account email")}
                  caption={
                    employee.id == 0
                      ? t(
                          "Email with login instructions will be sent to this address immediately after creating employee."
                        )
                      : undefined
                  }
                  size={"sm"}
                  paddingBottom
                  paddingTop
                />
                <Input
                  label={t("Email")}
                  type={"email"}
                  readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_email)}
                  {...register("email", {
                    value: undefined,
                  })}
                  error={errors.email?.message}
                />
              </>
            )}
            <Title header={t("General")} size={"sm"} paddingBottom paddingTop />
            {canViewField(employee, EmployeeFieldPermissions.employee_hired_on) && (
              <>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Datepicker
                      readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_hired_on)}
                      value={value}
                      onChange={onChange}
                      label={t("Hired on")}
                      required={true}
                      error={errors.date_hired_on?.message}
                    />
                  )}
                  control={control}
                  name={"date_hired_on"}
                />
              </>
            )}
            {canViewField(employee, EmployeeFieldPermissions.employee_date_birth) && (
              <>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Datepicker
                      label={t("Birth date")}
                      readOnly={
                        !canEditField(employee, EmployeeFieldPermissions.employee_date_birth)
                      }
                      value={value}
                      onChange={onChange}
                      error={errors.date_birth?.message}
                    />
                  )}
                  control={control}
                  name={"date_birth"}
                />
              </>
            )}
            {canViewField(employee, EmployeeFieldPermissions.employee_gender) && (
              <>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Select
                      label={t("Gender")}
                      readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_gender)}
                      options={genderOptions}
                      emptyTitle={t("Select")}
                      value={value}
                      onChange={onChange}
                      error={errors.gender?.message}
                    />
                  )}
                  control={control}
                  name={"gender"}
                />
              </>
            )}
            <Title header={t("Contacts")} size={"sm"} paddingBottom paddingTop />
            {canViewField(employee, EmployeeFieldPermissions.employee_phone) && (
              <>
                <Input
                  label={t("Phone")}
                  type={"text"}
                  readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_phone)}
                  {...register("phone", {
                    value: undefined,
                  })}
                  error={errors.phone?.message}
                />
              </>
            )}
            {canViewField(employee, EmployeeFieldPermissions.employee_email_personal) && (
              <>
                <Input
                  label={t("Email personal")}
                  type={"email"}
                  readOnly={
                    !canEditField(employee, EmployeeFieldPermissions.employee_email_personal)
                  }
                  {...register("email_personal", {
                    value: undefined,
                  })}
                  error={errors.email_personal?.message}
                />
              </>
            )}
            {canViewField(employee, EmployeeFieldPermissions.employee_phone_personal) && (
              <Input
                label={t("Phone personal")}
                type={"text"}
                readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_phone_personal)}
                {...register("phone_personal", {
                  value: undefined,
                })}
                error={errors.phone_personal?.message}
              />
            )}
            {canViewAnyOfFields(employee, [
              EmployeeFieldPermissions.employee_address_address_line_1,
              EmployeeFieldPermissions.employee_address_address_line_2,
              EmployeeFieldPermissions.employee_address_city,
              EmployeeFieldPermissions.employee_address_state,
              EmployeeFieldPermissions.employee_address_zip_code,
              EmployeeFieldPermissions.employee_address_country,
            ]) && (
              <>
                <Title header={t("Address")} size={"sm"} paddingBottom paddingTop />
                {canViewField(
                  employee,
                  EmployeeFieldPermissions.employee_address_address_line_1
                ) && (
                  <Input
                    label={t("Address line 1")}
                    type={"text"}
                    readOnly={
                      !canEditField(
                        employee,
                        EmployeeFieldPermissions.employee_address_address_line_1
                      )
                    }
                    {...register("address_1", {
                      value: undefined,
                    })}
                    error={errors.address_1?.message}
                  />
                )}
                {canViewField(
                  employee,
                  EmployeeFieldPermissions.employee_address_address_line_2
                ) && (
                  <Input
                    label={t("Address line 2")}
                    type={"text"}
                    readOnly={
                      !canEditField(
                        employee,
                        EmployeeFieldPermissions.employee_address_address_line_2
                      )
                    }
                    {...register("address_2", {
                      value: undefined,
                    })}
                    error={errors.address_2?.message}
                  />
                )}

                {canViewField(employee, EmployeeFieldPermissions.employee_address_country) && (
                  <Controller
                    disabled={
                      !canEditField(employee, EmployeeFieldPermissions.employee_address_country)
                    }
                    render={({ field: { value, onChange } }) => (
                      <Select
                        options={countries || []}
                        emptyTitle={t("Country")}
                        searchable
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    name={"country_id"}
                  />
                )}
                {canViewField(employee, EmployeeFieldPermissions.employee_address_state) && (
                  <Input
                    label={t("State")}
                    type={"text"}
                    readOnly={
                      !canEditField(employee, EmployeeFieldPermissions.employee_address_state)
                    }
                    {...register("state", {
                      value: undefined,
                    })}
                    error={errors.state?.message}
                  />
                )}
                {canViewField(employee, EmployeeFieldPermissions.employee_address_city) && (
                  <Input
                    label={t("City")}
                    type={"text"}
                    readOnly={
                      !canEditField(employee, EmployeeFieldPermissions.employee_address_city)
                    }
                    {...register("city", {
                      value: undefined,
                    })}
                    error={errors.city?.message}
                  />
                )}
                {canViewField(employee, EmployeeFieldPermissions.employee_address_zip_code) && (
                  <Input
                    label={t("Zip code")}
                    type={"text"}
                    readOnly={
                      !canEditField(employee, EmployeeFieldPermissions.employee_address_zip_code)
                    }
                    {...register("zip", {
                      value: undefined,
                    })}
                    error={errors.zip?.message}
                  />
                )}
              </>
            )}
            {canViewAnyOfFields(employee, [
              EmployeeFieldPermissions.employee_salary,
              EmployeeFieldPermissions.employee_salary_description,
            ]) && (
              <>
                <Title header={t("Compensation")} size={"sm"} paddingBottom paddingTop />
                {canViewField(employee, EmployeeFieldPermissions.employee_salary) && (
                  <Input
                    label={t("Salary")}
                    type={"number"}
                    readOnly={!canEditField(employee, EmployeeFieldPermissions.employee_salary)}
                    {...register("salary", {
                      value: undefined,
                    })}
                    error={errors.salary?.message}
                  />
                )}
                {canViewField(employee, EmployeeFieldPermissions.employee_salary) && (
                  <Input
                    label={t("Salary description")}
                    type={"text"}
                    readOnly={
                      !canEditField(employee, EmployeeFieldPermissions.employee_salary_description)
                    }
                    {...register("salary_description", {
                      value: undefined,
                    })}
                    error={errors.salary_description?.message}
                  />
                )}
              </>
            )}
          </Stack>
        </form>
      </Modal>
    </>
  );
}
