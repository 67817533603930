import { useTranslation } from "react-i18next";
import Stack from "../../../ui/stack/Stack.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { useCallback, useMemo } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { DepartmentEditModal } from "./widgets/DepartmentEditModal.tsx";
import { buildTree } from "./departments.ts";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui/table/Table.tsx";
import EmptyState from "../../../components/empty-state/EmptyState.tsx";
import { ApiDepartment } from "../../../types/department.types.ts";
import { ExpandableRow } from "../../../ui/table/table.types.ts";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import { EmployeeInlineCard } from "../../../components/employee-inline-card/EmployeeInlineCard.tsx";
import { Page } from "../../../components/page/Page.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { useDepartmentDelete, useDepartments } from "../../../queries/use-departments.query.ts";
import { useEffectOnce } from "react-use";

export default function Departments() {
  const { t } = useTranslation();

  const modalsService = useModals();
  const { data: departments, isLoading, isFetching } = useDepartments();
  const departmentDelete = useDepartmentDelete();

  const tableData = useMemo(() => {
    if (departments) {
      return buildTree(departments);
    }
    return [];
  }, [departments]);

  const handleEditOrCreate = useCallback((department: ApiDepartment | undefined = undefined) => {
    const departmentToEdit = department || {
      id: 0,
      title: "",
      parent_id: 0,
      employees_count: 0,
      direct_subordinates_count: 0,
    };
    modalsService.openModal(DepartmentEditModal, {
      department: departmentToEdit,
    });
  }, []);

  const handleDelete = useCallback((department: ApiDepartment) => {
    departmentDelete.mutate(department.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.departments.deleted, {
          [analyticProperties.id]: department.id,
        });
      },
    });
  }, []);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.departments.viewed);
  });

  const columnHelper = createColumnHelper<ExpandableRow<ApiDepartment>>();
  const columns = useMemo<ColumnDef<ExpandableRow<ApiDepartment>>[]>(
    () => [
      {
        header: t("Department"),
        accessorKey: "title",
        size: "auto" as unknown as number,
        cell: ({ row }) => (
          <Stack
            direction={"horizontal"}
            gap={"sm"}
            style={{
              paddingLeft: `${row.depth}rem`,
            }}
          >
            <div>
              {row.getCanExpand() && (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: "pointer" },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <FontAwesomeIcon icon={"fa-light fa-caret-down"} className={"w-4  h-4"} />
                  ) : (
                    <FontAwesomeIcon icon={"fa-light fa-caret-right"} className={"w-4  h-4"} />
                  )}
                </button>
              )}
            </div>
            <div>
              <Text className={"text-title"}>{row.original.item.title}</Text>
            </div>
          </Stack>
        ),
      },
      {
        header: t("Manager"),
        accessorKey: "manager",
        size: 200,
        cell: ({ row }) =>
          row.original.item.manager && <EmployeeInlineCard employee={row.original.item.manager} />,
      },
      {
        header: t("Employees"),
        cell: (row) => (
          <div className={"text-right px-2"}>{row.row.original.item.employees_count}</div>
        ),
        accessorKey: "employees_count",
        size: 100,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              onClick={() => {
                handleEditOrCreate(cell.row.original.item);
              }}
              variant={"secondary"}
            >
              {t("Edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleDelete(cell.row.original.item);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("Delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [tableData]
  );
  return (
    <>
      <Page>
        <Page.Header showBack={true} title={t("Departments")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Create new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Table
            data={tableData}
            isFetching={isFetching || departmentDelete.isPending}
            columns={columns}
            showSkeleton={isLoading}
            emptyStateComponent={<EmptyState />}
          />
        </Page.Content>
      </Page>
    </>
  );
}
