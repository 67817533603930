import Stack from "../../../../ui/stack/Stack";
import Table from "../../../../ui/table/Table";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound";
import EmptyState from "../../../../components/empty-state/EmptyState";
import { useTranslation } from "react-i18next";
import useModals from "../../../../ui/modal/modal.store";
import { useCallback, useMemo } from "react";
import { ApiCandidateDisqualificationReason } from "../../../../types/recruit/disqualification-reason.types";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service";
import Button from "../../../../ui/button/Button";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon";
import { CandidateDisqualificationReasonEditModal } from "./widgets/CandidateDisqualificationReasonEditModal.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import { Page } from "../../../../components/page/Page.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import {
  useDisqualificationDelete,
  useDisqualifications,
} from "../../../../queries/recruit/use-disqualifications.query.ts";
import { useEffectOnce } from "react-use";

export function CandidateDisqualificationReasons() {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { data: disqualificationReasons, isLoading, isFetching } = useDisqualifications();
  const disqualificationReasonDelete = useDisqualificationDelete();
  const columnHelper = createColumnHelper<ApiCandidateDisqualificationReason>();
  const columns = useMemo<ColumnDef<ApiCandidateDisqualificationReason>[]>(
    () => [
      {
        header: t("Title"),
        cell: (row) => <Text className={"text-title"}>{row.row.original.title}</Text>,
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      columnHelper.display({
        header: t("Actions"),
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              variant={"secondary"}
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
            >
              {t("Edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={async () => {
                  handleDeleteAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("Delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [disqualificationReasons]
  );

  const handleEditOrCreate = useCallback(
    (disqualificationReason: ApiCandidateDisqualificationReason | undefined = undefined) => {
      const toEdit = disqualificationReason || {
        id: 0,
        title: "",
      };
      modalsService.openModal(CandidateDisqualificationReasonEditModal, {
        disqualificationReason: toEdit,
      });
    },
    []
  );

  const handleDeleteAction = useCallback(
    (disqualificationReason: ApiCandidateDisqualificationReason) => {
      modalsService.openModal(ConfirmationPopup, {
        question: t("Do you want to remove reason?"),
        text: t(
          "If you remove this reason all candidates which have disqualification for this reason will be qualified"
        ),
        acceptTitle: t("Delete"),
        onAccept: async function () {
          try {
            await disqualificationReasonDelete.mutateAsync(disqualificationReason.id);
            analyticsService.trackEvent(analyticEvents.disqualificationReasons.deleted, {
              [analyticProperties.id]: disqualificationReason.id,
            });
          } catch {
            return;
          }
        },
      });
    },
    []
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.disqualificationReasons.viewed);
  });

  return (
    <>
      <Page>
        <Page.Header showBack={true} title={t("Disqualification reasons")}>
          <Stack>
            <Button
              onClick={() => handleEditOrCreate()}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Add reason")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Table
            data={disqualificationReasons || []}
            columns={columns}
            isFetching={isFetching || disqualificationReasonDelete.isPending}
            showSkeleton={isLoading}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={<EmptyState />}
          />
        </Page.Content>
      </Page>
    </>
  );
}
