import { EmployeePageComponentProps } from "./employee-page-components.types.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { EmployeeCard } from "../../../../components/employee-card/EmployeeCard.tsx";
import { useTranslation } from "react-i18next";
import { useEmployees } from "../../../../queries/employees/use-employees.query.ts";
import { useEffect, useState } from "react";
import { PaginationBlock } from "../../../../ui/pagination/PaginationBlock.tsx";
import { ApiEmployeeFilter } from "../../../../types/employees/employee.types.ts";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";
// import useFormatter from "../../../../hooks/use-formatter.hook.ts";
// import { ListItem } from "../../../../components/list-item/ListItem.tsx";

export function EmployeePageTabOverview({ employee }: EmployeePageComponentProps) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ApiEmployeeFilter>({
    page_number: 1,
    manager_ids: [employee.id],
  });
  // const { renderEmployeeName, renderDate, formatDurationPartialDate, transformDateToPartialDate } =
  //   useFormatter();

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      manager_ids: [employee.id],
      page_number: 1,
    }));
  }, [employee.id]);

  const { data: myEmployees, isLoading: isMyEmployeesIsLoading } = useEmployees(filter);

  return (
    <Stack direction={"vertical"} gap={"md"}>
      {/*<Title*/}
      {/*  size="md"*/}
      {/*  header={t("Timeline")}*/}
      {/*  caption={`${employee.first_name} is a member of our team for ${formatDurationPartialDate(*/}
      {/*    transformDateToPartialDate(employee.date_hired_on),*/}
      {/*    undefined*/}
      {/*  )}`}*/}
      {/*/>*/}
      {/*<ListItem*/}
      {/*  title={"Joined us"}*/}
      {/*  // value={`as ${employee.employee_position}`}*/}
      {/*  caption={renderDate(employee.date_hired_on)}*/}
      {/*  key={`joined`}*/}
      {/*/>*/}
      <Title
        size={"md"}
        header={t("Team")}
        caption={
          myEmployees ? t("employees_count", { count: myEmployees.metadata.total_items }) : "..."
        }
      />

      {isMyEmployeesIsLoading && (
        <Stack direction={"horizontal"} gap={"lg"}>
          <Stack className={"w-40"} gap={"sm"}>
            <Skeleton className={"w-40 h-40 rounded-2xl"} />
            <Skeleton className={"w-40 h-4"} />
            <Skeleton className={"w-40 h-4"} />
          </Stack>
          <Stack className={"w-40"} gap={"sm"}>
            <Skeleton className={"w-40 h-40 rounded-2xl"} />
            <Skeleton className={"w-40 h-4"} />
            <Skeleton className={"w-40 h-4"} />
          </Stack>
          <Stack className={"w-40"} gap={"sm"}>
            <Skeleton className={"w-40 h-40 rounded-2xl"} />
            <Skeleton className={"w-40 h-4"} />
            <Skeleton className={"w-40 h-4"} />
          </Stack>
        </Stack>
      )}

      <Box className={"flex-wrap flex flex-row gap-6"}>
        {myEmployees?.items.map((employeeItem) => (
          <EmployeeCard
            employee={employeeItem}
            size={"md"}
            key={`my-employee-${employeeItem.id}`}
          />
        ))}
      </Box>

      {!isMyEmployeesIsLoading && (myEmployees?.metadata.total_pages ?? 0) > 1 && (
        <PaginationBlock
          metadata={myEmployees?.metadata}
          onPageChange={(page) => {
            setFilter({ ...filter, page_number: page });
          }}
        />
      )}
    </Stack>
  );
}
