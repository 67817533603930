import { useMemo } from "react";
import { useEmployeeStore } from "../stores/employee.store";
import { Permissions, PermissionType } from "../types/role.types";

export const usePermissionCheck = () => {
  const { employee } = useEmployeeStore();

  const hasPermissionsToViewSettings = useMemo(() => {
    if (!employee) return false;
    const settingsPermissions = [
      Permissions.settings_departments,
      Permissions.settings_divisions,
      Permissions.settings_positions_levels,
      Permissions.settings_roles_permissions,
      Permissions.settings_organization,
      Permissions.settings_email_templates,
      Permissions.settings_pipelines,
      Permissions.settings_sources,
      Permissions.manage_candidates,
    ];
    return settingsPermissions.some(
      (permission) => employee.permissions[permission] === PermissionType.edit
    );
  }, [employee]);

  return { hasPermissionsToViewSettings };
};
