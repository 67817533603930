import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import Box from "../../../../ui/box/Box.tsx";
import { useRecruitAnalyticsStageFlow } from "../../../../queries/recruit/use-recruit-analytics.query.ts";
import {
  ApiAnalyticsAggregationDate,
  ApiAnalyticsRecruitingFilter,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import { RecruitingReportingButtonChooseAggregationDate } from "../components/RecruitingReportingButtonChooseAggregationDate.tsx";
import { RecruitingReportingButtonChoosePeriod } from "../components/RecruitingReportingButtonChoosePeriod.tsx";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { RecruitingReportingRecruitersSelector } from "../components/RecruitingReportingRecruitersSelector.tsx";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import { RecruitingStageFlowReportTable } from "./RecruitingStageFlowReportTable.tsx";
import { RecruitingReportingButtonChooseGroupBy } from "../components/RecruitingReportingButtonChooseGroupBy.tsx";
import { RecruitingReportingButtonFilters } from "../components/RecruitingReportingButtonFilters.tsx";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";
import ButtonIcon from "../../../../ui/button/ButtonIcon.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { useCallback, useState } from "react";
import { apiAnalytics } from "../../../../api/analytics/analytics.recruiting.api.ts";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import { PermissionType } from "../../../../types/role.types.ts";

export const RecruitingStageFlowReport = () => {
  const { t } = useTranslation();

  // const { getAvailableOptions } = useDatePeriods();

  const { employee } = useEmployeeStore();

  const [, setSearchParams] = useSearchParams();

  const { filter } = useRecruitingReports(AnalyticReportTypes.recruitingStageFlow);
  const { data: report, isLoading } = useRecruitAnalyticsStageFlow(filter);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.reporting.viewed, {
      [analyticProperties.title]: AnalyticReportTypes.recruitingStageFlow,
    });
  });

  const [isExporting, setIsExporting] = useState(false);
  const handleExportClick = useCallback(async () => {
    setIsExporting(true);
    const filterToExport: ApiAnalyticsRecruitingFilter = {
      ...filter,
      format: "xlsx",
    };
    analyticsService.trackEvent(analyticEvents.dataExported, {
      [analyticProperties.title]: AnalyticReportTypes.recruitingStageFlow,
    });

    const { data, headers, error } =
      await apiAnalytics.recruiting.stageFlowReportExport(filterToExport);

    setIsExporting(false);
    if (data && headers) {
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.log(error);
      // handleErrors(error);
    }
  }, [filter]);

  return (
    <>
      <Box className={"bg-light h-full overflow-y-auto"}>
        <Page.Header title={t("Hiring pipeline")}>
          {/*<Button onClick={handleDownloadCSV} size={"sm"}>*/}
          {/*  {t("Export to CSV")}*/}
          {/*</Button>*/}

          <Stack direction={"horizontal"} gap={"sm"} className={"overflow-hidden"}>
            <RecruitingReportingButtonChooseGroupBy
              value={filter.group_by}
              onChange={(group) => {
                setSearchParams((searchParams) => {
                  searchParams.set("group_by", group);
                  return searchParams;
                });
              }}
            />
            <RecruitingReportingButtonChooseAggregationDate
              value={filter.aggregation_date_mode || ApiAnalyticsAggregationDate.week}
              onChange={(e: ApiAnalyticsAggregationDate) => {
                setSearchParams((searchParams) => {
                  searchParams.set("aggregation_date_mode", e.toString());
                  return searchParams;
                });
              }}
            />
            <RecruitingReportingButtonChoosePeriod
              aggregationType={filter.aggregation_date_mode || ApiAnalyticsAggregationDate.week}
              dateFrom={filter.date_start}
              dateTo={filter.date_end}
            />

            <RecruitingReportingButtonFilters
              filter={filter}
              reportType={AnalyticReportTypes.recruitingStageFlow}
            />
            {employee && employee.permissions.reporting_export == PermissionType.edit && (
              <DropdownMenu
                trigger={<ButtonIcon icon={<FontAwesomeIcon icon={"fa-light fa-file-export"} />} />}
              >
                <Button
                  isLoading={isExporting}
                  onClick={handleExportClick}
                  leftIcon={<FontAwesomeIcon icon="fa-light fa-file-xls" />}
                  variant={"menu"}
                >
                  {t("Export to XLSX")}
                </Button>
              </DropdownMenu>
            )}
          </Stack>
        </Page.Header>

        <Page.Content>
          <Box>
            <RecruitingReportingRecruitersSelector
              recruiterIds={filter.recruiter_ids || []}
              onChange={(recruiterIds) => {
                setSearchParams((searchParams) => {
                  searchParams.set("recruiter_ids", recruiterIds.join(","));
                  return searchParams;
                });
              }}
            />
          </Box>

          <RecruitingStageFlowReportTable report={report} filter={filter} isLoading={isLoading} />
        </Page.Content>
      </Box>
    </>
  );
};
