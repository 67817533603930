import useModals from "../../../../ui/modal/modal.store.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";
import { ApiPosition, ApiPositionSavePayload } from "../../../../types/position.types.ts";
import { Input } from "../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import { usePositionSave } from "../../../../queries/use-positions.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { Title } from "../../../../components/title/Title.tsx";
import { RichEditor } from "../../../../ui/rich-editor/RichEditor.tsx";

export interface PositionEditModalProps extends ModalProps {
  position: ApiPosition;
}

export function PositionEditModal({ position, ...props }: PositionEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const positionSchema = yup.object({
    title: yup.string().required(i18n.t("Title is required")),
    levels: yup.array().of(
      yup.object().shape({
        id: yup.number(),
        title: yup.string().required(i18n.t("Level is required")),
      })
    ),
    description: yup.string(),
  });

  interface PositionSchema extends yup.InferType<typeof positionSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PositionSchema>({
    mode: "onSubmit",
    resolver: yupResolver(positionSchema),
    defaultValues: {
      title: position.title,
      levels: position.levels || [],
      description: position.description || "",
    },
  });

  const positionSave = usePositionSave(control);

  const {
    fields: levelFields,
    append: addLevel,
    remove: removeLevel,
    move,
  } = useFieldArray({
    control,
    name: "levels",
  });

  const onSubmit = handleSubmit(async (positionData) => {
    const levelsWithSort =
      positionData.levels?.map((level, index) => ({
        id: level.id,
        title: level.title,
        sort: index + 1,
      })) || [];

    const payload: ApiPositionSavePayload = {
      title: positionData.title,
      levels: levelsWithSort,
      description: positionData.description || "",
    };

    positionSave.mutate(
      { id: position.id, payload: payload },
      {
        onSuccess: (data) => {
          if (position.id == 0) {
            analyticsService.trackEvent(analyticEvents.positions.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.positions.edited, {
              [analyticProperties.id]: position.id,
            });
          }
          close(id);
        },
      }
    );
  });

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    move(source.index, destination.index);
  };

  return (
    <Modal
      {...props}
      title={t("Position")}
      withCloser
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} isLoading={positionSave.isPending} onClick={onSubmit}>
            {t("Save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Stack>
          <Input
            label={t("Title")}
            required={true}
            autoFocus={true}
            type="text"
            {...register("title")}
            error={errors.title?.message}
          />
          <Title
            header={t("Levels")}
            size={"xs"}
            paddingTop
            caption={t(
              "You can add, delete, or edit the levels that indicate the degree of performance within the role"
            )}
          />
          <Stack gap="sm">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="levels">
                {(provided) => (
                  <div
                    className={"flex flex-col gap-2"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {levelFields.map((field, index) => (
                      <Draggable key={field.id} draggableId={`item-${field.id}`} index={index}>
                        {(provided) => (
                          <div
                            className={"flex gap-2 items-baseline"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <div {...provided.dragHandleProps}>
                              <FontAwesomeIcon icon="fa-light fa-grip-dots-vertical" />
                            </div>
                            <Stack className={"grow"}>
                              <Input
                                label={t("Level")}
                                type="text"
                                {...register(`levels.${index}.title`)}
                                error={errors.levels?.[index]?.title?.message}
                              />
                            </Stack>
                            <Button
                              variant={"plain"}
                              leftIcon={<FontAwesomeIcon icon="fa-light fa-trash" />}
                              onClick={() => removeLevel(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>

          <Stack className={"pt-4"}>
            <Button
              size="sm"
              variant={"secondary"}
              onClick={() => addLevel({ title: "" })}
              type={"button"}
            >
              {t("Add level")}
            </Button>
          </Stack>
        </Stack>
        <Title header={t("Description")} paddingTop paddingBottom size={"xs"} />
        <Stack>
          <Controller
            render={({ field: { value, onChange } }) => (
              <RichEditor
                value={value || ""}
                onChange={onChange}
                placeholder={t("Add position's description")}
                error={errors.description?.message}
              />
            )}
            control={control}
            name={"description"}
          />
        </Stack>
      </form>
    </Modal>
  );
}
