import { useTranslation } from "react-i18next";
import { ApiPosition, ApiPositionFilter } from "../../../types/position.types.ts";
import { useCallback, useMemo, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "../../../ui/table/Table.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { PositionEditModal } from "./widgets/PositionEditModal.tsx";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import EmptyStateNothingFound from "../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../components/empty-state/EmptyState.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { Page } from "../../../components/page/Page.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { Search } from "../../../components/search/Search.tsx";
import { useDebounce, useEffectOnce } from "react-use";
import { usePositionDelete, usePositionsSearch } from "../../../queries/use-positions.query.ts";
import Box from "../../../ui/box/Box.tsx";
import { PaginationBlock } from "../../../ui/pagination/PaginationBlock.tsx";

export default function Positions() {
  const { t } = useTranslation();

  const modalsService = useModals();

  const [searchTerm, setSearchTerm] = useState("");
  useDebounce(
    () => {
      setFilter({
        page_number: 1,
        text: searchTerm,
      });
    },
    300,
    [searchTerm]
  );
  const [filter, setFilter] = useState<ApiPositionFilter>({
    page_number: 1,
    text: "",
  });
  const { data: positions, isLoading, isFetching } = usePositionsSearch(filter);
  const positionDelete = usePositionDelete();

  const handleEditOrCreate = useCallback((position: ApiPosition | undefined = undefined) => {
    const positionToEdit = position || {
      id: 0,
      title: "",
      employees_count: 0,
      levels: [],
      description: "",
    };
    modalsService.openModal(PositionEditModal, {
      position: positionToEdit,
    });
  }, []);

  const handleDelete = useCallback(async (positionId: number) => {
    positionDelete.mutate(positionId, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.positions.deleted, {
          [analyticProperties.id]: positionId,
        });
      },
    });
  }, []);

  const columnHelper = createColumnHelper<ApiPosition>();
  const columns = useMemo<ColumnDef<ApiPosition>[]>(
    () => [
      {
        header: t("Title"),
        cell: (row) => <Text className={"text-title"}>{row.row.original.title}</Text>,
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      {
        header: t("Levels"),
        cell: (row) => (
          <div className={""}>{row.row.original.levels.map((level) => level.title).join(", ")}</div>
        ),
        accessorKey: "levels",
        size: 300,
      },
      {
        header: t("Employees"),
        cell: (row) => <div className={"text-right"}>{row.row.original.employees_count}</div>,
        accessorKey: "employees_count",
        size: 100,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
              variant={"secondary"}
            >
              {t("Edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={() => {
                  const positionId = cell.row.original.id;
                  handleDelete(positionId);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("Delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [positions]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.positions.viewed);
  });

  return (
    <>
      <Page>
        <Page.Header showBack={true} title={t("Positions")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("Create new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Stack direction={"horizontal"}>
            <Search
              onInput={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
              type={"text"}
              placeholder={t("Search by name")}
              className={"w-96"}
            />
          </Stack>

          <Box className={isFetching ? "opacity-50" : undefined}>
            <Table
              data={positions?.items || []}
              columns={columns}
              showSkeleton={isLoading}
              showNotFoundError={filter.text != "" && positions?.metadata.total_items == 0}
              notFoundComponent={<EmptyStateNothingFound />}
              emptyStateComponent={<EmptyState />}
            />
          </Box>
          <PaginationBlock
            metadata={positions?.metadata}
            onPageChange={(page) => {
              setFilter({ ...filter, page_number: page });
            }}
          />
        </Page.Content>
      </Page>
    </>
  );
}
