import { useTranslation } from "react-i18next";
import Modal from "../../ui/modal/modal";
import Button from "../../ui/button/Button";
import useModals from "../../ui/modal/modal.store.ts";
import { ModalProps } from "../../ui/modal/modal.types.ts";
import { ApiEvent, ApiEventTypes } from "../../types/event.types.ts";
import Stack from "../../ui/stack/Stack.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import { ListItem } from "../list-item/ListItem.tsx";
import { add } from "date-fns";
import { Title } from "../title/Title.tsx";
import { UserListItem } from "../user-list/UserListItem.tsx";
import { ApiEmployeeShort } from "../../types/employees/employee.types.ts";
import { useEmployeeStore } from "../../stores/employee.store.ts";
import { EventEditModal } from "./EventEditModal.tsx";
import { useCallback } from "react";
import { ConfirmationPopup } from "../confirmation-popup/ConfirmationPopup.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { useEventsDelete } from "../../queries/use-events.query.ts";

export interface EventPreviewModalProps extends ModalProps {
  event: ApiEvent;
  organizer: ApiEmployeeShort;
  withDeleteButton?: boolean;
  withEditButton?: boolean;
}

export function EventPreviewModal({
  event,
  organizer,
  withDeleteButton = false,
  withEditButton = false,
  ...props
}: EventPreviewModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { renderDate, renderTime, renderEmployeeName } = useFormatter();
  const modalsService = useModals();
  const { employee } = useEmployeeStore();
  const useEventDelete = useEventsDelete();

  const handleDelete = useCallback(() => {
    modalsService.openModal(ConfirmationPopup, {
      question: t("Delete event"),
      text: t("This event will be deleting from all calendars for all guests"),
      acceptTitle: t("Delete"),
      onAccept: async function () {
        try {
          await useEventDelete.mutateAsync(event.id);

          analyticsService.trackEvent(analyticEvents.events.deleted, {
            [analyticProperties.id]: event.id,
            [analyticProperties.actionType]: "Calendar",
          });

          close(id);
        } catch {
          return;
        }
      },
    });
  }, [event]);

  return (
    <Modal
      {...props}
      layout="base"
      size={"md"}
      title={t("Event")}
      withCloser
      closeByEscEnabled
      actions={
        <Stack gap={"sm"}>
          <Button onClick={() => close(id)} size={"lg"} className={"w-full"}>
            {t("Close")}
          </Button>
          {employee?.id == organizer.id && withEditButton && (
            <Button
              onClick={() => {
                close(id);
                modalsService.openModal(EventEditModal, { event, eventId: event.id });
              }}
              size={"lg"}
              variant={"secondary"}
              className={"w-full"}
            >
              {t("Edit")}
            </Button>
          )}
          {employee?.id == organizer.id &&
            withDeleteButton &&
            event.type == ApiEventTypes.other && (
              <Button onClick={handleDelete} size={"lg"} variant={"danger"} className={"w-full"}>
                {t("Delete")}
              </Button>
            )}
        </Stack>
      }
    >
      <Stack>
        <ListItem preTitle={t("Title")} title={event.title} />
        <ListItem
          preTitle={t("Date")}
          title={`${renderDate(event.date_event)} · ${renderTime(event.time_start)} — ${renderTime(add(event.time_start, { minutes: event.duration_minutes }))}`}
          caption={event.timezone}
        />
        {event.comment && <ListItem preTitle={t("Description")} title={event.comment} />}
        <Title header={t("Guests")} size={"xs"} paddingBottom paddingTop />
        <Stack gap={"md"}>
          <UserListItem
            primaryText={renderEmployeeName(organizer)}
            avatarUrl={organizer.avatar_url}
            text2={t("Organizer")}
          />
          {event.guests.map((guest) => (
            <UserListItem
              key={guest.id}
              primaryText={guest.first_name}
              avatarUrl={guest.avatar_url}
            />
          ))}
        </Stack>
        <Title header={t("Settings")} size={"xs"} paddingBottom paddingTop />
        <ListItem
          preTitle={t("Event privacy")}
          title={
            event.is_private
              ? t("Private (visible only to you)")
              : t("Public (visible to everyone)")
          }
        />
        {event.interview && (
          <ListItem
            preTitle={t("Invite candidate")}
            title={event.interview.is_invite_candidate ? t("Yes") : t("No")}
          />
        )}
      </Stack>
    </Modal>
  );
}
