import { Page } from "../../../components/page/Page.tsx";
import { Chart, ChartNodeData } from "../../../components/chart/Chart.tsx";
import { useTranslation } from "react-i18next";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { apiEmployees } from "../../../api";
import { useCallback, useEffect, useState } from "react";
import { ApiEmployeeChartItem } from "./employees-chart.types.ts";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { EmployeeViewModal } from "../widgets/EmployeeViewModal.tsx";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";

export function EmployeesChart() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const [chart, setChart] = useState<ChartNodeData<ApiEmployeeChartItem>[]>([]);
  const [expanded, setExpanded] = useState<Set<string>>(new Set([]));
  const { renderEmployeeName } = useFormatter();
  const { openModal } = useModals();
  const [searchParams] = useSearchParams();

  const loadEmployees = useCallback(async (managerId: number | undefined) => {
    const { data: employees } = await apiEmployees.chart(managerId);

    return employees;
  }, []);

  const loadEmployee = useCallback(async (employeeId: number | string) => {
    const { data: employee } = await apiEmployees.getById(employeeId);

    return employee;
  }, []);

  useEffect(() => {
    const initializeChart = async () => {
      const employeeId = searchParams.get("employee_id")
        ? Number(searchParams.get("employee_id"))
        : undefined;

      if (employeeId) {
        const aimEmployee = await loadEmployee(employeeId);
        const manager = aimEmployee?.employee_position.manager;
        const managerEmployee = manager?.id ? await loadEmployee(manager.id) : undefined;

        if (aimEmployee) {
          const subordinates = await loadEmployees(employeeId);
          const chats: ChartNodeData<ApiEmployeeChartItem>[] = [];

          if (managerEmployee) {
            chats.push({
              title: renderEmployeeName(managerEmployee),
              caption: managerEmployee.employee_position.position?.title,
              img_url: managerEmployee.avatar_url,
              id: `manager_${managerEmployee.id}`,
              parentId: null,
              count: 1,
              isRoot: true,
            });
          }

          chats.push({
            title: renderEmployeeName(aimEmployee),
            caption: aimEmployee.employee_position.position?.title,
            img_url: aimEmployee.avatar_url,
            id: `${aimEmployee.id}`,
            parentId: manager ? `manager_${manager.id}` : null,
            count: subordinates?.length ?? 0,
            isRoot: !managerEmployee,
          });

          setChart(chats);

          if (subordinates) {
            // необходимо, чтобы сотрудник в дереве был "раскрыт"
            subordinates.length && setExpanded(new Set([`${aimEmployee.id}`]));

            appendEmployeesToChart(subordinates, employeeId);
          }
        }
      } else {
        // Загрузка организации как корня, если employeeId не указан
        const { data: employees } = await apiEmployees.chart(undefined);

        if (employees) {
          setChart([
            {
              title: employee?.organization?.title ?? t("Organization"),
              img_url: employee?.organization?.logo_url ?? "/skailer-dark.svg",
              id: "root",
              parentId: null,
              count: employees.length,
              isRoot: true,
            },
          ]);

          appendEmployeesToChart(employees, undefined);
        }
      }
    };

    initializeChart();
  }, [searchParams]);

  const appendEmployeesToChart = useCallback(
    (employees: ApiEmployeeChartItem[], parentId: number | undefined) => {
      const nodes: ChartNodeData<ApiEmployeeChartItem>[] = employees.map((employee) => ({
        title: renderEmployeeName(employee.employee),
        caption: employee.employee.employee_position.position?.title,
        img_url: employee.employee.avatar_url,
        id: `${employee.employee.id}`,
        parentId: parentId ? `${parentId}` : "root",
        count: employee.direct_subordinates_count,
        isRoot: false,
      }));

      setChart((prevChart) => [...prevChart, ...nodes]);
    },
    []
  );

  const onTitleClick = useCallback(
    async (nodeId: string) => {
      if (nodeId == "root") return;

      const employee = await loadEmployee(nodeId);

      employee && openModal(EmployeeViewModal, { employee });
    },
    [chart]
  );

  const onExpandClick = useCallback(
    (nodeId: string, needLoadData: boolean) => {
      if (nodeId == "root") return;
      if (!needLoadData) return;

      const id = Number(nodeId);

      nodeId &&
        loadEmployees(id).then((employees) => {
          employees && appendEmployeesToChart(employees, id);
        });
    },
    [chart]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employees.viewedChart);
  });

  return (
    <Page className={"bg-light/80 h-full"}>
      <Page.Header showBack={true} title={t("Organization")}></Page.Header>
      {chart.length > 0 && (
        <Chart
          data={chart}
          onExpandClick={onExpandClick}
          onTitleClick={onTitleClick}
          expandedSet={expanded}
        />
      )}
    </Page>
  );
}
